#sibone {
  .modal__video[data-component='modal'] {
    > .modal-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s linear;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;

      @include mq('small') {
        padding-top: 17.5px; //as per live
        padding-bottom: 17.5px; //as per live
        padding-left: 40px;
        padding-right: 40px;
      }

      &.is-open {
        transition: none;

        .modal-content {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .modal-content {
        margin: var(--emu-common-spacing-none);
        background-color: var(--emu-common-colors-white);
        border-radius: 3px;
        position: relative;
        max-width: 100%;
        transition: transform 0.3s linear;
        transform: translateY(-50px);
        max-width: 500px; //as per live

        @include mq('tablet') {
          max-width: 800px; //as per live
        }
        @include mq('large') {
          height: 80%; //as per live
        }
        .aaaem-text {
          padding-top: 20px;
          padding-bottom: 10px;
          padding-left: 10px;
          padding-right: 10px;

          @include mq('large') {
            padding-top: 50px;
          }

          p {
            font-size: 5vw; //as per live
            line-height: normal;
            color: var(--emu-semantic-colors-primary-dark-blue-300);
            font-weight: var(--emu-common-font-weight-black);

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-wide-xl);
            }

            @include mq('large') {
              font-size: 40px;
            }
          }
        }

        > div {
          height: 100%;
          > div.aaaem-container {
            height: inherit;
            display: flex;
            flex-direction: column;
          }
        }

        .embed {
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 40px;
          flex: 1;
          .emu-embed,
          .aaaem-embed__oembed {
            height: 100%;
          }

          @include mq('large') {
            margin-top: 20px;
            margin-left: 60px;
            margin-right: 60px;
            margin-bottom: 60px;
          }

          .aaaem-embed__oembed:not(.no-ratio),
          .aaaem-embed__oembed > iframe {
            height: 150px; //as per live
            padding: var(--emu-common-spacing-none);

            @include mq('large') {
              height: 100%; //as per live
            }
          }
        }
        .image {
          position: absolute;
          bottom: 0;
          width: 50px; //as per live
          bottom: 20px;
          left: 20px;

          @include mq('large') {
            width: 100px; //as per live
            bottom: 41px;
          }
        }

        .close {
          padding: var(--emu-common-spacing-none);
          opacity: 0.5;
          position: absolute;
          top: -39px;
          right: -8px;

          &:hover {
            opacity: 0.75;
          }
          > span {
            color: var(--emu-common-colors-white);
            font-size: 55px;
            font-weight: var(--emu-semantic-font-weight-500);
            font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
          }
        }
      }
    }
  }
}
