#article-detail {
  .aaaem-image {
    display: flex;
    justify-content: center;

    img {
      width: 100%; // To match live site
      padding-top: 25px;
      padding-bottom: 25px;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      max-width: 750px;
    }
  }

  .article--image-full-width {
    img {
      max-width: unset;
    }
  }

  .article--img-justify-start {
    justify-content: flex-start;
  }

  .article--bigger-img {
    img {
      max-width: 1000px; //as per live
    }
  }

  // shows image with natural dimensions
  .article--default-dimension-img {
    img.cmp-image__image {
      width: auto;
      height: auto;
      max-width: 100%;
      padding: var(--emu-common-spacing-none);
    }
  }

  // Utility to set visibility by device/screen size Start//
  // visible on desktop size
  .desktop-view {
    display: none;

    @include mq('large') {
      display: block;
    }
  }

  // visible on tablet size & larger
  .tablet-desktop-view {
    display: none;

    @include mq('medium') {
      display: flex;
    }
  }

  // visible on tablet size & smaller
  .tablet-mobile-view {
    display: block;

    @include mq('large') {
      display: none;
    }
  }

  // visible on mobile size
  .mobile-view {
    display: block;

    @include mq('medium') {
      display: none;
    }
  }
  // Utility to set visibility by device/screen size End//
}
