// Article Image Start
.article-image {
  text-align: center;

  > img {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 1000px; // To match live site
    width: 100%; // To match live site
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    margin-left: auto;
    margin-right: auto;
  }
}
// Article Image End

// Image button section START
.abjp-button {
  &__hover-image {
    display: none;
  }

  &-mobile,
  &-desktop {
    cursor: pointer;

    &:hover {
      .abjp-button {
        &__image {
          display: none;
        }

        &__hover-image {
          display: block;
        }
      }
    }
  }

  &-mobile {
    max-width: 95%; // As per live site.
    margin-top: 20px;
    margin-bottom: 15px;
  }

  &-desktop {
    max-width: 50%; // As per live site.
    margin-top: 50px;
    margin-bottom: 40px;
  }
}
// Image button section END

// Article pages common styling START
.article-page__container-wrapper {
  padding: var(--emu-common-spacing-none);

  .breadcrumb .cmp-breadcrumb {
    padding-top: 27px;
    padding-bottom: var(--emu-common-spacing-medium);
    width: 100%;

    &__list {
      @include mq('medium') {
        width: 95%; // As per live site.
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__item {
      line-height: 22px;

      @include mq('medium') {
        line-height: 1.5; // As per live site.
      }
    }
  }

  .article-page {
    &__banner {
      &-image {
        padding-bottom: 15px;

        @include mq('medium') {
          padding-bottom: 30px;
        }

        img {
          max-width: min(100%, 1000px); // As per live site.
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-content {
        padding-left: 17.5px; // As per live site.
        padding-right: 17.5px; // As per live site.
        margin-bottom: var(--emu-common-spacing-medium);

        @include mq('medium') {
          width: 95%; // As per live site.
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 18px;
        }
      }

      &-title {
        .cmp-title__text {
          font-size: var(--emu-semantic-font-sizes-narrow-xxl);
          line-height: var(--emu-semantic-line-heights-narrow-xxl);
          letter-spacing: 2px;
          font-weight: var(--emu-semantic-font-weight-500);
          padding-bottom: 10px;
          margin: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-small);
          color: var(--emu-semantic-colors-primary-dark-gray-100);

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            line-height: var(--emu-semantic-line-heights-wide-xxl);
            letter-spacing: 2.4px; // As per live site.
            padding-bottom: 15px;
          }
        }
      }

      &-text {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.75px; // As per live site.
        margin: var(--emu-common-spacing-none);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          line-height: 36px;
          letter-spacing: 0.9px;
        }

        p {
          font-family: var(--emu-semantic-font-families-heading);
          font-weight: var(--emu-semantic-font-weight-500);
        }
      }
    }

    &__card-list {
      padding: var(--emu-common-spacing-none);
      padding-top: 25px;
      width: 90%; // As per live site.
      margin-left: auto;
      margin-right: auto;

      @include mq('medium') {
        width: 95%; // As per live site.
        padding-left: 17.5px; // As per live site.
        padding-right: 17.5px; // As per live site.
      }

      .article-card-list {
        &__header {
          padding-bottom: 15px;

          &-title {
            margin: var(--emu-common-spacing-none);
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: var(--emu-common-spacing-small);

            @include mq('medium') {
              padding-top: 20px;
              padding-bottom: 20px;
            }
          }
        }

        &__action {
          line-height: var(--emu-semantic-line-heights-narrow-xxl);

          @include mq('medium') {
            line-height: 36.5px; // As per live site.
          }

          .plus {
            padding-left: 7px;
            padding-right: 7px;
          }
        }
      }
    }
  }
}
// Article pages common styling END
