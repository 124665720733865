#sibone {
  .hero-banner {
    // To match live site
    background-size: auto !important; //needed to overwrite figma tokens
    background-repeat: repeat !important; //needed to overwrite figma tokens
    padding: var(--emu-common-spacing-none);
    @include mq('medium') {
      padding-top: 20px;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      padding-bottom: 54px;
    }
    &__img {
      img {
        max-width: 1166px; //as per live
        max-height: 546px; //as per live
        width: 100%;
        height: auto;
        margin: auto;
      }
    }

    &__scroll-img {
      position: relative;
      img {
        @include mq('medium') {
          bottom: -35px; //as per live
          max-width: 13px; //as per live
          max-height: 72px; //as per live
          width: 100%;
          height: auto;
          position: absolute;
          left: 50px;
          margin: auto; //as per live
        }
        @include mq('large') {
          bottom: -10px; //as per live
        }
      }
    }
    &__youtube-img {
      margin-top: -10px; //as per live
      position: relative;
      @include mq('medium') {
        // To match live site
        margin-top: -100px;
        // To match live site
        margin-bottom: 100px;
      }
      a {
        position: absolute;
        top: 0;
        right: 20px;
        @include mq('medium') {
          // To match live site
          top: -46px;
        }
      }
      img {
        // To match live site
        width: 160px;
        @include mq('medium') {
          // To match live site
          width: 270px;
        }
      }
    }
  }

  .fat-cells {
    &--first-section {
      width: 90%;
      padding-top: 12%; //as per live
      margin-top: 114px;
      @include mq('medium') {
        padding-top: 65px;
        margin-top: 15px;
        width: 100%;
        padding-left: 17.5px;
        padding-right: 17.5px;
        margin-bottom: 35px;
      }
      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-large);
      }
    }
    &__content {
      width: 96%;

      @include mq('medium') {
        display: flex;
      }
      @include mq('large') {
        justify-content: center;
      }

      > div:first-child.image {
        @include mq('medium') {
          width: 32%;
          max-width: 405px;
        }
      }
      > div:last-child.container {
        @include mq('medium') {
          width: 68%;
          padding-top: 20px;
          padding-left: 25px;
          max-width: 662px;
        }
      }

      &__title {
        margin-top: 8%;
        padding-top: 20px;

        @include mq('medium') {
          margin-top: var(--emu-common-spacing-none);
          padding-top: var(--emu-common-spacing-none);
        }

        p {
          margin-bottom: 3%;
          font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
          // To match live site
          font-size: 6.258vw;
          font-weight: var(--emu-common-font-weight-black);
          color: var(--emu-semantic-colors-primary-dark-blue-100);
          line-height: 1.227;
          text-align: center;
          @include mq('medium') {
            font-size: 30px;
            text-align: left;
          }
          @include mq('large') {
            font-size: 48px;
          }
        }
      }
      &__description {
        @include mq('medium') {
          margin-top: -3px;
        }

        p {
          // To match live site
          font-size: 3.733vw;
          font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
          line-height: 1.6;
          letter-spacing: 0.3px;
          color: var(--emu-semantic-colors-primary-dark-gray-900);
          margin-bottom: 30px;
          text-align: center;
          @include mq('medium') {
            font-size: 15px;
            text-align: left;
            margin-bottom: 20px;
          }
          @include mq('large') {
            font-size: 20px;
          }
        }
      }
      &__btn {
        width: 96%;
        display: flex;
        max-width: 560px; //as per live
        transition: 0.4s;
        font-weight: var(--emu-common-font-weight-black);
        font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          max-width: 350px; //as per live
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 10px;
          padding-right: 10px;
        }
        @include mq('large') {
          max-width: 560px; //as per live
        }

        &:hover {
          .aaaem-button__icon {
            &::before,
            &::after {
              background-color: var(--emu-common-colors-white);
            }
          }
        }

        .aaaem-button__icon {
          margin-top: -4%;
          margin-right: 5%;
          margin-left: 5%;

          @include mq('medium') {
            margin-left: 8%;
          }

          @include mq('large') {
            margin-top: -3%;
          }

          &::before,
          &::after {
            width: 2px;
            height: 4.8vw;
            @include mq('medium') {
              height: 24px; //as per live
            }
          }
        }

        span:last-child {
          font-size: 4.4vw;
          line-height: 1.5;
          @include mq('medium') {
            font-size: 15px;
          }
          @include mq('large') {
            max-width: 560px; //as per live
            font-size: 26px;
          }
        }
      }
    }
    &__img {
      width: 100%;
      max-width: 676px; //as per live
      margin: var(--emu-common-spacing-none) auto;
    }

    &--second-section {
      padding-bottom: 57px;
      padding-left: 17.5px;
      padding-right: 17.5px;

      @include mq('medium') {
        max-width: 800px; //as per live
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }
    }
    &__table {
      // To match live site
      margin-top: 5%;
      text-align: justify;
      position: relative;
      height: var(--emu-common-sizing-none);
      overflow: hidden;
      transition: height 0.35s ease-in-out;

      @include mq('medium') {
        margin-top: 37px;
        padding-left: 17.5px;
        padding-right: 17.5px;
      }

      @include mq('tablet') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        th {
          background: var(--emu-semantic-colors-primary-dark-blue-600);
          border-bottom: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-white);
          width: 35vw; //as per live
          @include mq('medium') {
            width: 264px; //as per live
          }
        }
        td {
          border: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-primary-light-gray-700);
          border-left: none;
        }
        td,
        th {
          // To match live site
          font-size: 3.467vw;
          letter-spacing: 0;
          padding-top: 14px;
          padding-right: 3%;
          padding-left: 3%;
          padding-bottom: 15px;
          font-weight: var(--emu-common-font-weight-bold);
          text-align: left;
          line-height: 100%;
          list-style-type: none;
          font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
          color: var(--emu-semantic-colors-primary-dark-gray-900);
          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            padding-right: 30px;
            padding-left: 30px;
          }
        }
      }
    }
  }

  .episode {
    background: var(--emu-semantic-colors-primary-light-gray-800); //as per live

    &__intro {
      width: max-content;
      padding-bottom: 35px;

      @include mq('medium') {
        padding-bottom: 80px;
      }

      @include mq('tablet') {
        padding-bottom: 55px;
      }

      &-container {
        padding-top: 10%; //as per live
        padding-bottom: calc(8% - 25px); //as per live
        padding-left: 17.5px;
        padding-right: 17.5px;

        @include mq('medium') {
          padding-bottom: var(--emu-common-spacing-none);
          padding-top: 55px;
        }
      }

      &-img {
        &-desktop {
          @include mq('medium') {
            max-width: 450px;
          }

          @include mq('large') {
            max-width: 100%;
          }
        }

        &-mobile {
          max-width: 588px;
        }
      }

      &-description {
        margin-top: 5%;
        margin-bottom: 20px;
        @include mq('medium') {
          margin-top: 20px;
        }
        p {
          line-height: 1.7;
          letter-spacing: 0.5px;
          font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
          font-size: 3.733vw;
          color: var(--emu-semantic-colors-primary-dark-gray-900);

          @include mq('medium') {
            font-size: 20px;
          }

          sub {
            font-family: var(--emu-semantic-font-families-body);
            font-size: inherit;
            line-height: inherit;
            vertical-align: baseline;
          }

          br {
            @include mq('medium') {
              &:first-child,
              &:last-child {
                display: none;
              }
            }
          }
        }
      }
    }

    &__card {
      background: linear-gradient(
        180deg,
        var(--emu-common-colors-white) 80%,
        var(--emu-semantic-colors-primary-dark-blue-100) 0
      ); //as per live
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      @include mq('medium') {
        overflow: hidden;
        background: linear-gradient(
          90deg,
          var(--emu-semantic-colors-primary-dark-blue-100) 30%,
          var(--emu-common-colors-white) 0,
          var(--emu-common-colors-white)
        ); //as per live
        flex-direction: row-reverse;
      }

      &-container {
        display: flex;
        flex-wrap: wrap;

        @include mq('medium') {
          max-width: 764px; //as per live
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('tablet') {
          max-width: 1280px;
          width: 100%; //as per live
        }

        @include mq('large') {
          width: 96%; //as per live
        }

        .container {
          width: 30%; //as per live
          margin-right: 1.5%;
          margin-left: 1.5%;
          margin-bottom: 20px;
          display: flex;
          cursor: pointer;
          text-align: center;
          border: var(--emu-common-border-width-medium) solid
            var(--emu-semantic-colors-primary-dark-blue-100);

          @include mq('medium') {
            width: 24.5%; //as per live
            margin-right: 0.25%; //as per live
            margin-left: 0.25%; //as per live
          }
          @include mq('tablet') {
            border-radius: 3px;
          }
        }
      }

      > div.image {
        width: 30%;
        position: relative;
        margin-top: -9px; //to match live

        @include mq('medium') {
          margin-bottom: -8px; //to match live
          margin-top: var(--emu-common-spacing-none);
        }

        .aaaem-image {
          position: relative;
          max-width: 50px; //as per live
          width: auto;
          left: -140%; //as per live
          top: 18px;

          @include mq('medium') {
            left: 0;
            top: 3px;
            max-width: 40px; //as per live
            margin-left: auto;
            margin-right: auto;
          }

          @include mq('large') {
            max-width: 50px;
            top: 1.5px; //needed to match live
            margin-top: var(--emu-common-spacing-xs);
          }
        }
        &::after {
          position: relative;
          content: '';
          top: 17px;
          left: auto;
          border-color: #ffc600 var(--emu-common-colors-transparent)
            var(--emu-common-colors-transparent); //color used once
          border-style: solid;
          border-width: 10px 7px 0;
          right: auto;

          @include mq('medium') {
            top: 12px;
          }
          @include mq('medium') {
            top: 11px;
          }
        }
      }

      > div.text {
        @include mq('medium') {
          width: 70%;
          height: 100%;
        }
      }

      > div:last-child {
        @include mq('medium') {
          width: var(--emu-common-sizing-none);
          height: var(--emu-common-sizing-none);
        }
      }

      .aaaem-text {
        margin-bottom: 5px;
        padding-top: 20px;
        height: 100%;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
          padding-top: var(--emu-common-spacing-none);
        }

        p {
          height: inherit;
          display: flex;
          justify-content: center;

          @include mq('large') {
            align-items: center;
          }

          a {
            color: var(--emu-semantic-colors-primary-dark-blue-100);
            font-family: 'logotypejp_mp_b_1\.1', sans-serif;
            line-height: normal;
            font-size: var(--emu-common-line-heights-narrow-large);
            text-decoration: none;
            font-weight: var(--emu-common-font-weight-bold);

            @include mq('medium') {
              font-size: 22px;
              font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
            }

            @include mq('large') {
              margin-top: -4px; //needed to match live
            }

            &:hover,
            &:visited &:focus {
              color: var(--emu-semantic-colors-primary-dark-blue-100);
            }

            span.emphasis {
              font-family: 'logotypejp_mp_b_1\.1', sans-serif; //as per live
              font-size: clamp(1rem, 2.4vw, 1.8rem); //as per live
              line-height: 1;
              height: auto;
              display: block;
              color: var(--emu-semantic-colors-primary-dark-gray-600);
              letter-spacing: 0.5px;

              @include mq('medium') {
                font-size: clamp(10px, 1.1vw, 14px); //as per live
                margin-top: var(--emu-common-spacing-xs);
              }
            }
          }
        }
      }

      &-link {
        &.aaaem-text {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          padding: var(--emu-common-spacing-none);
          p > a {
            width: 100%;
            height: 100%;
            font-size: 0; //to overwrite default font
          }
        }
      }
    }

    &__details {
      padding-left: 17.5px;
      padding-right: 17.5px;
      padding-bottom: 81px;
      position: relative;
      padding-top: 25px;

      @include mq('medium') {
        padding-top: 65px;
        padding-bottom: 81px;
      }

      &-title {
        margin-bottom: 5%;

        @include mq('medium') {
          margin-bottom: 25px;
        }

        h2 {
          font-weight: var(--emu-common-font-weight-black);
          font-size: 6.519vw;
          font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
          color: #1560b4; //used once
          margin: var(--emu-common-sizing-none);
          line-height: 1;

          @include mq('medium') {
            font-size: 40px;
          }

          @include mq('large') {
            font-size: 54px;
          }
        }
      }

      .embed {
        .aaaem-embed {
          max-width: 760px; //as per live
          height: 150px;

          @include mq('medium') {
            max-width: 620px; //as per live
            height: 450px; //as per live
          }

          @include mq('large') {
            max-width: 760px; //as per live
            height: 450px; //as per live
          }

          .aaaem-embed__oembed,
          .aaaem-embed__oembed:not(.no-ratio) > iframe {
            height: inherit;
            padding: var(--emu-common-spacing-none);
          }
        }
      }

      &-content {
        position: relative;
        width: 96%;
        max-width: 1158px; //as per live
        margin-top: 42px;
        border: var(--emu-common-border-width-thick) solid
          var(--emu-semantic-colors-primary-dark-blue-100);
        border-radius: 6px;
        background-color: var(--emu-common-colors-white);

        &-title {
          background-color: var(--emu-semantic-colors-primary-dark-blue-100);
          padding-left: 19vw;
          padding-top: 20px;
          padding-bottom: 20px;

          @include mq('medium') {
            padding-left: var(--emu-common-spacing-none);
          }

          p {
            font-weight: var(--emu-common-font-weight-black);
            font-size: 4.954vw;
            letter-spacing: 0.5px;
            line-height: 1.15;
            color: var(--emu-common-colors-white);
            font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
            @include mq('medium') {
              font-size: 22px;
              letter-spacing: 2.5px;
              line-height: 1.5;
            }

            @include mq('large') {
              font-size: 40px;
            }
          }
        }

        &-emoji {
          position: absolute;
          left: 1vw;
          top: -14px; //as per live
          width: 20vw; //as per live
          height: 19vw; //as per live
          background-size: 100%;

          @include mq('medium') {
            width: 100px; //as per live
            height: 100px; //as per live
            top: -22px; //as per live
            left: 10%;
          }

          @include mq('large') {
            width: 130px; //as per live
            height: 115px; //as per live
            left: 15%;
            top: -14px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &-inner {
          padding-left: 11.2%; //as per live
          padding-right: 11.2%; //as per live
          padding-bottom: 15px;
          margin-top: 5.5%; //as per live

          @include mq('medium') {
            margin-top: 30px;
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 30px;
            display: flex;

            > div.image {
              width: 30%;
              max-width: 320px; //as per live
            }
            > div.container {
              width: 70%;
              padding-left: 36px;
            }
          }

          &-title {
            margin-top: 8%;
            margin-bottom: 2%;
            @include mq('medium') {
              margin-bottom: 12px;
              margin-top: var(--emu-common-sizing-none);
            }
            h5 {
              margin: var(--emu-common-sizing-none);
              font-size: 3.733vw;
              line-height: 1.45;
              font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
              letter-spacing: 0.65px;

              @include mq('medium') {
                font-size: 15px;
                letter-spacing: 0.9px;
                line-height: 1.2;
              }
              @include mq('large') {
                font-size: 26px;
              }
            }
          }

          &-description {
            p {
              font-size: 2.933vw;
              line-height: 1.84;
              font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
              color: var(--emu-semantic-colors-primary-dark-gray-900);
              letter-spacing: 0.65px;

              @include mq('medium') {
                font-size: 10px;
                letter-spacing: 0.9px;
              }

              @include mq('large') {
                font-size: var(--emu-common-font-sizes-wide-medium);
              }

              span.emphasis {
                line-height: 1.84;
                margin-top: 5px;
                font-size: 2vw;
                letter-spacing: normal;

                @include mq('medium') {
                  font-size: 9px;
                }

                @include mq('large') {
                  font-size: 13px;
                }
              }
            }
          }

          &--btn-wrapper {
            margin-top: 10px;
            margin-bottom: 25px;

            @include mq('medium') {
              margin-bottom: 38px;
              margin-top: var(--emu-common-spacing-none);
            }

            .aaaem-button__primary-filled {
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 10px;
              padding-right: 10px;
              width: 80%;
              @include mq('medium') {
                padding-top: 15px;
                padding-bottom: 15px;
                padding-left: 15px;
                padding-right: 15px;
              }

              @include mq('large') {
                max-width: 560px; //as per live
                padding-top: 23px;
                padding-bottom: 23px;
                padding-left: 10px;
                padding-right: 10px;
              }

              .cmp-button__text {
                font-size: 4.233vw;
                line-height: 20px;

                @include mq('medium') {
                  font-size: 15px;
                }

                @include mq('large') {
                  font-size: var(--emu-semantic-font-sizes-wide-xl);
                }

                &::after {
                  top: 22%;
                }
              }
            }
          }
        }
      }

      &-divider {
        &::after {
          position: absolute;
          content: '';
          width: 97%;
          height: var(--emu-common-sizing-xxs);
          border-top: var(--emu-common-border-width-thin) dashed
            var(--emu-common-colors-black);
          bottom: 0;
          left: 2%;
        }
      }
    }
  }

  .steps-section {
    padding-top: 12%;
    padding-bottom: 12%;
    padding-left: 17.5px; //as per live
    padding-right: 17.5px; //as per live

    @include mq('medium') {
      padding-top: 50px;
      padding-bottom: 50px;
      width: 96%;
    }

    @include mq('large') {
      padding-top: 75px;
      padding-bottom: 80px;
    }

    @include mq('x-large') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    .aaaem-image {
      @include mq('medium') {
        width: 450px; //as per live
      }

      @include mq('large') {
        width: auto;
        img {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &__sub-details {
      margin-bottom: 8%;
      margin-top: 20px;

      @include mq('medium') {
        margin-bottom: 25px;
      }

      @include mq('large') {
        margin-bottom: 50px;
        margin-top: 20px;
      }

      p {
        line-height: 1.7;
        letter-spacing: 0.5px;
        font-size: 3.733vw;
        color: var(--emu-semantic-colors-primary-dark-gray-900);
        font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);

        @include mq('medium') {
          letter-spacing: 0.05px; //to match live
          font-size: 13px; //to match live
        }

        @include mq('large') {
          font-size: 20px;
        }
      }
    }

    &__text-with-lines {
      padding-top: 14px;
      padding-bottom: 10px;
      margin-bottom: 7%;
      border-top: var(--emu-common-border-width-medium) solid
        var(--emu-semantic-colors-primary-dark-blue-200);
      border-bottom: var(--emu-common-border-width-medium) solid
        var(--emu-semantic-colors-primary-dark-blue-200);

      @include mq('medium') {
        max-width: 450px;
        padding-top: 10px;
        padding-bottom: 12px;
        margin-bottom: var(--emu-common-spacing-large);
      }

      @include mq('large') {
        padding-top: 13px;
        padding-bottom: 13px;
        min-width: 800px;
      }

      p {
        font-size: 6.667vw;
        line-height: 1;
        font-weight: var(--emu-common-font-weight-black);
        color: var(--emu-semantic-colors-primary-dark-blue-200);
        @include mq('medium') {
          font-size: 15px;
        }
        @include mq('large') {
          font-size: 34px;
        }
      }
    }

    &__img-container {
      padding-top: 15px;

      @include mq('medium') {
        display: flex;
        margin-bottom: 62px;
        grid-gap: 35px;
      }

      @include mq('large') {
        grid-gap: 70px;
      }

      > div.image:last-child {
        padding-bottom: 15px;
        @include mq('medium') {
          padding-bottom: var(--emu-common-spacing-none);
        }
        &::after {
          display: none;
        }
      }

      div.image {
        width: 82%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 45px;
        position: relative;

        @include mq('medium') {
          width: 30%;
          max-width: 380px; //as per live
          padding-bottom: var(--emu-common-sizing-none);
          padding-bottom: 15px;
          place-items: center;
          padding-bottom: var(--emu-common-spacing-none);
        }

        &::after {
          content: '';
          background: url('./assets/images/two-arrows.png');
          position: absolute;
          bottom: 2vw;
          width: 7vw;
          max-width: 43px; //as per live
          height: 7vw;
          max-height: 37px; //as per live
          transform: rotate(90deg);
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          background-size: 100% 100%;
          background-repeat: no-repeat;

          @include mq('medium') {
            width: 27px;
            height: 27px;
            margin-right: -33px;
            top: 38%;
            transform: revert;
          }

          @include mq('large') {
            width: 43px; //as per live
            height: 37px; //as per live
            margin-right: -55px;
            top: 44%;
          }
        }

        .aaaem-image {
          max-width: 625px; //as per live
          width: 100%;

          img {
            width: inherit;
          }
        }
      }
    }

    .aaaem-button {
      margin-top: 20px;
      margin-bottom: 20px;

      @include mq('large') {
        margin-top: var(--emu-common-spacing-none);
      }
    }

    &--btn {
      //This is needed, utility classes not overwriting the styles
      &-dektop {
        display: none;
        @include mq('medium') {
          display: block;
        }
      }
      &-mobile {
        display: block;
        @include mq('medium') {
          display: none;
        }
      }
    }
  }

  .articleinfo {
    padding-top: 12%;
    padding-bottom: 12%;
    padding-left: 17.5px; //as per live
    padding-right: 17.5px; //as per live
    &--with-bg {
      background: var(--emu-semantic-colors-primary-light-gray-800);
    }
    @include mq('medium') {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    @include mq('large') {
      padding-top: 75px;
      padding-bottom: 80px;
    }

    &--text p {
      margin-top: 20px;
      margin-bottom: 8%; //as per live
      font-size: 3.733vw; //as per live
      line-height: 1.7; //as per live
      font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
      color: var(--emu-semantic-colors-primary-dark-gray-900);
      padding-bottom: 15px;
      letter-spacing: 0.5px; //as per live

      @include mq('medium') {
        margin-top: 20px;
        margin-bottom: 25px;
        font-size: 12px;
        line-height: 21px; //as per live
      }
      @include mq('large') {
        margin-bottom: 50px;
        font-size: 20px;
        line-height: 34px;
      }
    }

    &__img {
      &-desktop,
      &-mobile {
        width: 100%;
        > img {
          margin: inherit;
        }
      }

      &-desktop {
        @include mq('medium') {
          max-width: 450px; //as per live
        }

        @include mq('large') {
          max-width: 1111px; //as per live
        }
      }

      &-mobile {
        max-width: 676px; //as per live
      }
    }
  }

  //search icon
  .search {
    position: fixed;
    top: 80px;
    right: 15px;
    width: 100px; //as per live
    bottom: 40px;
    //To get this content above header and below modal
    //don't have a variable to get item b/w header and modal
    z-index: 501;

    @include mq('medium') {
      top: auto;
      bottom: 40px;
      width: 150px; //as per live
      right: 30px;
    }

    @include mq('large') {
      top: 140px;
      right: 70px;
      width: 176px; //as per live
    }
  }

  // to remove spacing in title/paragraph for 1,3,5...
  .remove-spacing__odd {
    br {
      @include mq('medium') {
        &:nth-child(2n + 1) {
          display: none;
        }
      }
    }
  }

  // to remove spacing b/w 2,4,6...
  .remove-sacing__even {
    br {
      @include mq('medium') {
        &:nth-child(2n) {
          display: none;
        }
      }
    }
  }
}
