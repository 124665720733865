.line-modal {
  &[data-component='modal'] .modal {
    &-wrapper {
      transition: all 0.3s linear;
    }

    &-content {
      margin-top: 40%; // to match live site
      margin-bottom: 40%; // to match live site
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      opacity: 0;
      transition: all 0.3s linear;
      transform: translateY(-50px); // to match live site

      @include mq('medium') {
        margin-top: 35%;
        margin-bottom: 35%;
        max-width: 500px; // to match live site
      }

      @include mq('large-plus') {
        margin-top: 1.75rem;
        margin-bottom: 1.75rem;
      }

      .close {
        position: var(--emu-common-spacing-unset);
        margin-left: auto;
        background-color: #c1c1c1; // Used once
        border-radius: 50%; // to match live site
        color: var(--emu-common-colors-white);
        height: 47px; // to match live site
        width: 47px; // to match live site
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;

        @include mq('medium') {
          height: 50px; // to match live site
          width: 50px; // to match live site
        }

        // The default close icon is not matching the live site.
        // Made the changes in the before and after Psedo elements to match live site
        &::before,
        &::after {
          content: '';
          height: 18px;
          position: absolute;
          background: var(--emu-common-colors-white);
          width: var(--emu-common-spacing-xxs);

          @include mq('medium') {
            height: 22px;
          }
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }

        span {
          display: none;
        }

        &:hover,
        &:active {
          opacity: 1;
        }
      }

      .cmp-container {
        margin-top: 10px;

        .cmp-image__image {
          height: auto;
        }
      }
    }
  }
}
.modal--scale {
  animation: scale-animation 0.3s linear;
}

[data-component='modal'] {
  > .modal-wrapper {
    display: unset;
    opacity: 0;
    pointer-events: none;

    &.is-open {
      opacity: 1;
      pointer-events: unset;
    }
  }
}

.js-modal-opened {
  overflow: hidden;

  .line-modal[data-component='modal'] .modal-content {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scale-animation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.hide-line-modal {
  overflow: auto; // When modal open, it hides the overflow content. So reverting it to actual behaviour
  .line-modal {
    display: none;
  }
}
