#home {
  .article-banner-card {
    &__link {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: var(--emu-common-other-z-index-layer);

      span {
        display: none;
      }

      @include mq('large-plus') {
        width: 97%;
      }
    }

    &__content-section {
      z-index: var(--emu-common-other-z-index-layer);
    }
  }

  .aaaem-carousel {
    &__item-container {
      .card {
        position: relative;
      }
    }
  }

  .social {
    background: var(--emu-semantic-colors-secondary-brown-200);
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;

    @include mq('medium') {
      padding-top: 90px;
      padding-bottom: 80px;
    }

    @include mq('large-plus') {
      padding-top: 85px;
      padding-bottom: 95px;
    }

    &__youtube,
    &__line {
      a {
        display: inline-block;
      }

      img {
        max-width: 150px; // To match live site
        display: block;

        @include mq('medium') {
          max-width: unset;
        }
      }
    }

    &__youtube {
      a {
        margin-bottom: var(--emu-common-spacing-small);
        margin-right: 10px;

        @include mq('medium') {
          margin-right: var(--emu-common-spacing-none);
        }
      }
    }

    &__text h4 {
      font-weight: var(--emu-common-font-weight-regular);
      margin-top: 10px;
      margin-left: var(--emu-common-spacing-none);
      margin-right: var(--emu-common-spacing-none);
      color: var(--emu-common-colors-white);
      letter-spacing: 1.05px; // To match live site
      margin-bottom: 15px;
      font-size: 20px;
      line-height: 30px;

      @include mq('medium') {
        letter-spacing: 3px;
        padding-top: 10px;
        padding-bottom: 15px;
        margin: var(--emu-common-spacing-none);

        > br:first-child {
          display: none;
        }
      }
    }
  }

  .article-card-list {
    &__card-wrapper {
      gap: 20px;

      @include mq('medium') {
        gap: 21px;
        grid-row-gap: 40px;
      }

      @include mq('tablet') {
        gap: 35px;
      }
    }
  }

  .interview {
    padding-top: 10px;

    @include mq('medium') {
      padding-top: 20px;
    }

    .article-card-list {
      &__card {
        &-text {
          @include mq('medium') {
            margin-bottom: 10px;
            font-size: 13px;
            line-height: 21px;
            display: inline-block;
          }

          h4 {
            @include mq('medium') {
              letter-spacing: 0.75px;
              font-size: 13px;
              line-height: 25px;
              margin-bottom: var(--emu-common-spacing-small);
            }

            @include mq('large-plus') {
              letter-spacing: 0.9px;
              font-size: 18px;
              line-height: 36px;
              margin-bottom: var(--emu-common-spacing-none);
            }
          }
        }

        &-tag-wrapper {
          @include mq('medium') {
            position: relative;
            top: -4px;
          }
        }

        &-tag-link {
          margin-bottom: 5px;

          @include mq('medium') {
            letter-spacing: 0.6px;
          }

          @include mq('x-large') {
            letter-spacing: 0.55px;
          }
        }
      }

      &__action-wrapper {
        // media query is needed to match the design
        @media (min-width: 890px) and (max-width: 1090px) {
          margin-top: 25px;
        }

        // media query is needed to match the design
        @media (min-width: 1280px) {
          margin-top: 25px;
        }
      }
    }
  }
}
