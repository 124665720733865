#systemrequirements {
  .breadcrumb {
    .cmp-breadcrumb {
      padding-top: 37px;
      padding-bottom: 46px;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      &__list {
        display: flex;
      }
    }
  }
  .system-requirement {
    &__container {
      padding-top: 5px;
      margin-bottom: 44px; //to match live site
      @include mq('medium') {
        padding-bottom: 27px;
      }
    }

    &__title h2 {
      font-size: 24px;
      font-family: var(--emu-semantic-font-families-body);
      line-height: 34px;
      margin-bottom: var(--emu-common-spacing-small);
      color: var(--emu-semantic-colors-primary-dark-gray-100);
    }

    &__sub-title {
      margin-bottom: 70.5px; // To match live site

      & p {
        font-size: 1rem; // to match live site
        font-weight: var(--emu-common-font-weight-regular);
        line-height: 1.54; // To match live site
        vertical-align: baseline;
        font-family: var(--emu-semantic-font-families-heading);
      }
    }

    &__body {
      p {
        font-weight: var(--emu-common-font-weight-regular);
        color: var(--emu-semantic-colors-primary-dark-gray-100);
        margin-bottom: 15px;
        font-size: 1rem; // to match live site
        line-height: 1.54; // to match live site
        font-family: var(--emu-semantic-font-families-heading);

        b {
          font-family: var(--emu-semantic-font-families-body);
          line-height: 1.5; // to match live site

          i {
            font-weight: var(--emu-common-font-weight-black);
            font-style: normal;
          }
        }

        u {
          b {
            font-family: var(--emu-semantic-font-families-heading);
          }
        }

        @include mq('medium') {
          & .emphasis {
            display: none;
          }
        }
      }
    }
  }
}
