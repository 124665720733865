.header {
  &__navlink {
    opacity: 0;
    transition: opacity var(--emu-common-other-time-transition-short)
      ease-in-out;
    background-color: var(--emu-semantic-colors-secondary-brown-300);
    width: var(--emu-common-sizing-none); // for animation of mobile menu
    height: var(--emu-common-sizing-none); // for animation of mobile menu
    overflow: hidden;

    @include mq('large') {
      width: fit-content;
      height: max-content;
      margin-left: auto;
      margin-right: auto;
      opacity: 1;
      background-color: var(--emu-common-colors-white);
      display: flex;
    }

    &-favicon {
      display: none;

      @include mq('large') {
        width: var(--emu-common-sizing-medium); // as per live
        margin-top: 10px;
      }
    }

    .emu-navigation {
      &__content-wrapper {
        position: relative;
      }
    }

    &__menu {
      background-color: inherit;

      &-links,
      .emu-navigation__content-wrapper > nav {
        @include mq('large') {
          background-color: var(--emu-common-colors-transparent);
        }

        ul {
          margin-top: 11px;

          @include mq('medium') {
            flex-direction: column;
          }

          @include mq('large') {
            flex-direction: row;
            margin-top: var(--emu-common-spacing-none);
            margin-left: 40px;
            margin-bottom: 6px;
          }

          li {
            margin-left: 15px;
            margin-right: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-white);

            @include mq('large') {
              padding-right: 15px;
              margin-right: 45px;
              margin-left: var(--emu-common-spacing-none);
              padding-left: 15px;
              padding-top: 17px;
            }

            a {
              font-size: var(--emu-semantic-font-sizes-narrow-large);
              line-height: 24px;
              transition: var(--emu-common-other-time-transition-base);
              margin: var(--emu-common-spacing-none);
              opacity: 1;
              transform: translateX(15px);

              @include mq('medium') {
                line-height: var(--emu-semantic-line-heights-wide-xl);
              }

              @include mq('large') {
                color: var(--emu-semantic-colors-primary-dark-gray-400);
                letter-spacing: 1.5px;
                font-size: 15px;
                line-height: 20px;
                transition: none;
                border-bottom: var(--emu-common-border-width-thin) solid
                  var(--emu-common-colors-transparent);
                padding-left: var(--emu-common-spacing-none);
                font-weight: var(--emu-common-font-weight-bold);
                transform: translateX(0px);
              }

              &:hover {
                text-decoration: underline;

                @include mq('large') {
                  background-color: var(--emu-common-colors-transparent);
                  text-decoration: none;
                  border-bottom-color: var(
                    --emu-semantic-colors-primary-dark-gray-100
                  );
                }
              }
            }
          }
        }
      }
      &-search-container {
        &.aaaem-container {
          margin-top: 50px;
          margin-bottom: 57px;
          width: 93%; //as per live
          display: flex;
          position: relative;

          @include mq('medium') {
            margin-top: 71px;
            margin-bottom: 49px;
            width: 97%; //as per live
            height: 45px; //as per live
          }

          @include mq('large') {
            display: none;
          }

          > .container {
            width: 100%;

            .cmp-form {
              position: relative;

              input {
                width: 100%;
                padding-top: 6px;
                padding-bottom: 6px;
                padding-left: 12px;
                padding-right: 40px;
                font-size: var(--emu-common-font-sizes-narrow-medium);
                line-height: 28px;
                border-width: var(--emu-common-border-width-none);

                @include mq('medium') {
                  height: 45px; // as per live
                }
              }

              > div.button {
                position: absolute;
                right: 9px;
                top: 10px;
                width: auto;

                @include mq('medium') {
                  top: 14px;
                  right: 10px;
                }
              }
            }
          }
        }
      }

      &-youtube,
      &-line {
        width: 232px; //as per live
        height: 72px; //as per live
        margin-bottom: 20px;

        @include mq('large') {
          display: none;
        }
      }
    }
  }
}

.show-mobile-menu {
  .header {
    &__navlink {
      position: absolute;
      top: 53px;
      padding-top: 15px;
      opacity: 1;
      width: 100%;
      height: calc(100vh - 46px); // as per live

      &__menu {
        &-links,
        .emu-navigation__content-wrapper > nav {
          ul {
            li {
              a {
                transform: translateX(0);
                transition-delay: var(--emu-common-other-time-transition-short);
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.header-sticky {
  .header__navlink {
    &-favicon {
      @include mq('large') {
        display: block;
      }
    }
  }
}
