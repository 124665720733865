#sibone {
  .cmp-experiencefragment--footer {
    background: var(--emu-semantic-colors-primary-dark-blue-200);
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 27px;
    padding-left: 27px;

    @include mq('medium') {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .sb-footer__content {
      @include mq('1280px') {
        //needed this breakpoint to match live
        padding-left: 27px;
        padding-right: 27px;
      }

      p {
        color: var(--emu-common-colors-white);
        font-family: Noto, sans-serif; //to match live - used once in footer
        font-size: 3.733vw; //as per live
        line-height: 1;
        @include mq('medium') {
          line-height: 10px;
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
        }

        span.emphasis {
          font-family: var(--emu-semantic-font-families-body);
          text-align: right;
          margin-top: 25px;
          display: block;
          @include mq('medium') {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
