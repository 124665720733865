[data-component='clinic-search-button'] {
  .clinic-search-button {
    &__container {
      margin-left: auto;
      margin-right: auto;

      .clinic-search-button__image {
        img {
          display: block;
        }
      }

      &:hover {
        .clinic-search-button__image {
          display: none;
        }

        .clinic-search-button__image--hover {
          display: block;
        }
      }

      &--desktop {
        display: none;
        max-width: 50%; // To match live site
        margin-top: 50px; // To match live site
        margin-bottom: 40px; // To match live site

        @include mq('medium') {
          display: block;
        }
      }

      &--mobile {
        max-width: 95%; // To match live site
        margin-top: 20px;
        margin-bottom: 15px;

        @include mq('medium') {
          display: none;
        }
      }
    }

    &__image {
      text-decoration: none;

      img {
        width: 100%;
      }

      &--hover {
        display: none;
      }
    }
  }
}
