#sibone {
  .modal__qrcode {
    .aaaem-text {
      font-size: 22px;
      font-weight: var(--emu-semantic-font-weight-500);
      color: var(--emu-common-colors-white);
      line-height: 1.5; //as per live
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .aaaem-image {
      margin-top: 30px;
      margin-bottom: 30px;
      background-color: var(--emu-common-colors-white);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 280px; //as per live
      padding-top: 60px;
      padding-bottom: 60px;
      padding-left: 60px;
      padding-right: 60px;
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;
      @include mq('small') {
        padding-top: 134px;
        padding-bottom: 134px;
        padding-left: 134px;
        padding-right: 134px;
        width: 490px; //as per live
      }
      > img {
        width: 160px; //as per live
        @include mq('small') {
          width: auto;
        }
      }
    }

    .close {
      display: none;
    }

    .modal {
      &-wrapper {
        transition: all 0.3s linear;

        padding-left: 20px;
        padding-right: 20px;

        &.is-open {
          .modal-content {
            transform: translateY(0);
          }
        }

        .modal-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 96vh;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          max-width: 900px; //as per live
          width: 100%;
          border-radius: 20px;
          background: var(--emu-semantic-colors-primary-dark-blue-400);

          padding-top: 30px;
          padding-bottom: 20px;
          border: var(--emu-common-border-width-thin) solid rgba(0, 0, 0, 0.2); //as per live
          transform: translateY(-50px);
          transition: all 0.3s linear;
        }
      }

      &-cta-container {
        .button {
          width: 100%;
          .aaaem-button {
            width: inherit;
            background: var(--emu-semantic-colors-primary-dark-blue-400);
            > span {
              width: inherit;
              text-align: center;
              line-height: 1.5;
              font-size: 22px;
              letter-spacing: 2px;
              font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
              font-weight: var(--emu-semantic-font-weight-500);
            }
          }
        }

        > div:last-child {
          display: none;
        }
      }
    }
  }
}
