.categorypage {
  [data-component='article-card-list'].article-card-list {
    padding-top: var(--emu-common-spacing-none);

    @include mq('medium') {
      padding-top: 10px;
    }

    .article-card-list {
      &__inner {
        @include mq('medium') {
          width: 100%;
        }
      }

      &__card-wrapper {
        gap: 20px;

        @include mq('medium') {
          gap: 21px;
        }

        @include mq('tablet') {
          gap: 35px;
        }
      }
    }
  }

  // related articles section
  .relatedArticles {
    @include mq('medium') {
      // margin-bottom: 30px;
    }
  }

  // new article section
  .articlelist {
    [data-component='article-card-list'].article-card-list {
      padding-top: 25px;

      @include mq('medium') {
        padding-top: 55px;
      }

      .article-card-list__card-wrapper {
        .article-card-list__card:last-child {
          border: none;
        }
      }
    }
  }
}
