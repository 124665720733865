#doctor-section-container {
  .doctor-section {
    &__main {
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 33px;
      padding-left: 20px;
      background-color: var(--emu-semantic-colors-primary-light-gray-100);

      @include mq('medium') {
        padding-bottom: 25px;
      }

      @include mq('tablet') {
        padding-bottom: 45px;
      }

      @include mq('large') {
        padding-bottom: 13px;
      }

      @include mq('1180px') {
        padding-bottom: 53px;
        padding-top: 24px;
      }
    }

    &__title {
      h2 {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: 21px;
        letter-spacing: 1.6px;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-small);
        font-family: var(--emu-semantic-font-families-body);
        border: none;
        padding: var(--emu-common-spacing-none);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: 34px;
          letter-spacing: 0;
        }

        @include mq('xx-large') {
          margin-top: 21px;
        }
      }
    }

    &__main-container {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      padding-top: 25px;
      padding-bottom: 12px;

      @include mq('medium') {
        padding-bottom: 20px;
      }

      @include mq('x-large') {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
      }
    }

    &__container {
      display: flex;
      max-width: 100%;
      width: 100%;
      flex-direction: row;
      height: 100%;
      align-items: flex-start;
      cursor: default;
      padding-bottom: 80px;
      background-color: var(--emu-semantic-colors-primary-light-gray-100);

      &:last-child {
        padding-bottom: var(--emu-common-spacing-none);
      }

      @include mq('medium') {
        padding-bottom: 90px;
      }

      @include mq('large-plus') {
        align-items: center;
      }

      // to match live site
      @include mq('1180px') {
        align-items: flex-start;
      }

      @include mq('x-large') {
        gap: 30px;
        align-items: center;
      }
    }

    &__img-wrapper {
      flex: 0 0 30%;

      @include mq('medium') {
        margin-right: 25px;
      }

      @include mq('large-plus') {
        margin-right: var(--emu-common-spacing-none);
        margin-left: 30px;
        margin-top: 15px;
      }

      // to match live site
      @include mq('1180px') {
        margin-top: var(--emu-common-spacing-none);
        margin-left: var(--emu-common-spacing-none);
        margin-right: var(--emu-common-spacing-xxs);
      }

      @include mq('x-large') {
        margin-left: 40px;
      }
    }

    &__desk-img {
      display: block;
      border-radius: 30%;
      width: 100px; // to match live site
      height: auto; // to match live site

      @include mq('medium') {
        margin-top: 15px;
        width: 100%;
        padding: var(--emu-common-spacing-none) 17.5px 12px;
      }

      @include mq('tablet') {
        padding: var(--emu-common-spacing-none);
      }

      @include mq('large-plus') {
        margin-top: var(--emu-common-spacing-none);
      }

      // to match live site
      @include mq('1180px') {
        padding-right: 20px;
      }

      @include mq('x-large') {
        width: 400px; // to match live site
        padding-right: var(--emu-common-spacing-none);
        margin-right: -4px;
        margin-top: 31px;
      }
    }

    &__content {
      margin-bottom: 20px;
      margin-left: 10px;

      @include mq('medium') {
        margin-left: var(--emu-common-spacing-none);
      }

      @include mq('large-plus') {
        margin-top: 32px;
        margin-left: 29px;
      }

      // to match live site
      @include mq('1180px') {
        margin-top: var(--emu-common-spacing-none);
        margin-left: -2px;
      }

      @include mq('x-large') {
        margin-bottom: var(--emu-common-spacing-none);
      }

      @include mq('xx-large') {
        margin-left: -12px; //needed to match live
        margin-top: 28px;
      }

      h2 {
        font-size: var(--emu-semantic-font-sizes-wide-xxl);
        line-height: var(--emu-semantic-line-heights-narrow-xxl);
        color: var(--emu-semantic-colors-primary-dark-gray-100);
        margin-bottom: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-none);
        font-family: var(--emu-semantic-font-families-heading);
        border: none;
        padding: var(--emu-common-spacing-none);
        letter-spacing: 0; // to match live
      }
    }

    &__clinic-container {
      position: relative;
      margin-left: min(-50%, -115px); // to match live site

      @include mq('medium') {
        margin-left: var(--emu-common-spacing-none);
        padding-top: var(--emu-common-spacing-none);
      }
    }

    &__clinic-title {
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      line-height: 22px;
      letter-spacing: 0.65px;
      margin-bottom: var(--emu-common-spacing-none);
      color: var(--emu-semantic-colors-primary-light-gray-900);
      font-weight: var(--emu-common-font-weight-bold);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-xxl);
        line-height: 27px;
        letter-spacing: 0;
      }

      p {
        margin-bottom: var(--emu-common-spacing-none);
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        color: inherit;
        font-weight: inherit;
        margin-top: var(--emu-common-spacing-none);
        font-family: var(--emu-semantic-font-families-body);
      }

      &--address {
        color: var(--emu-semantic-colors-primary-light-gray-900);
        font-weight: var(--emu-common-font-weight-regular);
        p {
          font-family: var(--emu-semantic-font-families-heading);
        }

        a {
          text-decoration: none;
          color: var(--emu-semantic-colors-primary-light-gray-900);

          &:hover {
            text-decoration: underline;
            text-decoration-color: var(
              --emu-semantic-colors-primary-dark-gray-500
            );
          }
        }
      }
    }

    &__description {
      margin-bottom: 20px;
      margin-top: var(--emu-common-spacing-medium);
      @include mq('medium') {
        margin-bottom: 60px;
      }
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: 22px;
        color: var(--emu-semantic-colors-primary-light-gray-900);
        letter-spacing: 0.65px;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-medium);
        font-family: var(--emu-semantic-font-families-heading);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          letter-spacing: 0.9px;
          line-height: 34px;
        }
      }
    }
  }
}
