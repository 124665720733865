header.experiencefragment {
  position: sticky;
  top: 0;
  height: 53px; //as per live
  z-index: var(--emu-common-other-z-index-header);
  background-color: var(--emu-common-colors-white);
  border-bottom: var(--emu-common-border-width-thin) solid rgba(0, 0, 0, 0.05);

  @include mq('large') {
    height: auto;
    position: relative;
    border-bottom-width: var(--emu-common-border-width-none);
  }

  .header {
    .aaaem-button,
    .aaaem-button:active,
    .aaaem-button:focus,
    .aaaem-button:hover {
      padding: var(--emu-common-spacing-none);
      border-width: var(--emu-common-border-width-none);
      background-color: var(--emu-common-colors-transparent);
      outline-width: 0;
    }

    &__search-text {
      display: none;
    }

    &__content {
      > div:nth-child(2) {
        @include mq('large') {
          display: flex;
          justify-content: center;
          background-color: var(--emu-common-colors-white);
          border-top: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-primary-light-gray-500);
          border-bottom: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-primary-light-gray-500);
        }
      }
    }

    &__menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 6px;
      padding-left: var(--emu-common-spacing-medium);
      padding-right: 15px;

      @include mq('large') {
        padding-top: var(--emu-common-spacing-none);
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      > div:first-child {
        @include mq('large') {
          margin-left: auto;
          margin-right: auto;
        }
      }

      > div:nth-child(2) {
        @include mq('large') {
          position: absolute;
          right: 40px;
          top: 45px;
        }
        @include mq('1260px') {
          top: 55px;
        }
      }

      &-logo {
        &-black,
        &-white {
          width: auto;
          height: auto; //as per live
          margin-top: -7px;

          img {
            width: 275px;
            margin-top: 15px;
            margin-bottom: 15px;
          }
        }

        &-black {
          @include mq('large') {
            display: none;
          }
        }

        &-white {
          display: none;
        }

        &-desktop {
          display: none;

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
            width: auto; // as per live
            height: auto;
            margin-top: -2px;
            display: block;
            img {
              margin-top: 40px;
              width: 400px;
              margin-bottom: 35px;
            }
          }
          @include mq('1260px') {
            margin-top: -7px;
            img {
              width: 672px;
            }
          }
        }
      }

      .cmp-form {
        display: flex;
        align-items: center;
        margin-top: -4px;

        &-text {
          display: flex;
          input {
            display: none;
            font-size: 10px;
            line-height: 15px;
            height: 24px; //as per live
            width: 120px; //as per live
            border-width: var(--emu-common-border-width-none);
            margin-bottom: auto;
            border: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-black);
            padding-top: 5px;
            padding-right: 25px;
            padding-left: 5px;
            padding-bottom: 5px;
            border-radius: var(--emu-common-border-radius-xs);

            &::placeholder {
              color: #5a5a5a; // used once
            }

            @include mq('large') {
              width: 230px; // as per live
              display: block;
              background-color: var(
                --emu-semantic-colors-primary-light-gray-400
              );
              border-width: var(--emu-common-border-width-none);
              padding-top: 6px;
              padding-bottom: 6px;
              padding-left: 12px;
              padding-right: 60px;
              font-size: var(--emu-semantic-font-sizes-wide-medium);
              line-height: var(--emu-common-line-heights-wide-large);
              height: auto;
              border-radius: var(--emu-common-border-radius-none);
            }
          }

          input.js-toggle-on {
            display: block;
          }
        }

        > div.button {
          padding-left: 10px;
          @include mq('large') {
            display: none;
          }
        }
      }

      &__search-input-container {
        display: none;
        position: relative;
        margin-right: 6px;

        @include mq('large') {
          margin-right: var(--emu-common-spacing-none);
          display: flex;
        }

        > div.button {
          .aaaem-button {
            position: absolute;
            top: -9px;
            right: 3px;

            @include mq('large') {
              top: 0;
              right: 0;
            }

            &.js-toggle-on {
              top: 3px;
              right: 3px;

              @include mq('large') {
                top: 0;
                right: 0;
              }
            }
          }

          @include mq('large') {
            top: 9.3px; // to match live
            right: 21px;
          }
        }
      }

      &__hamburger {
        &.aaaem-button {
          position: relative;
          padding-top: 11px;
          padding-bottom: 9px;
          margin-bottom: 9px;

          &:hover,
          &:active,
          &:focus {
            padding-top: 11px;
            padding-bottom: 9px;
            margin-bottom: 9px;
          }

          > span {
            width: 20px; //as per live
            height: 0.75px; //as per live
            background-color: var(--emu-semantic-colors-primary-dark-gray-100);

            &::before,
            &::after {
              transition: all 0.15s;
              content: '';
              position: absolute;
              width: inherit;
              height: inherit;
              left: 0;
              background-color: inherit;
            }

            &::before {
              top: 4px;
            }

            &::after {
              bottom: 2px;
            }
          }
        }
      }
    }
  }
}

//toggle mobile menu styles
.show-mobile-menu {
  header.experiencefragment {
    background-color: var(--emu-semantic-colors-secondary-brown-300);
    border-bottom: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-white);

    .header {
      &__menu {
        background-color: inherit;

        &-logo {
          &-black {
            display: none;
          }

          &-white {
            display: block;
            background-color: var(--emu-semantic-colors-secondary-brown-300);
          }
        }

        &__search-input-container {
          display: none;
        }

        &__hamburger {
          &.aaaem-button {
            > span {
              background-color: var(--emu-common-colors-transparent);

              &::before,
              &::after {
                background-color: var(--emu-common-colors-white);
              }

              &::after {
                bottom: 8px;
                left: 0px;
                transform: rotate(133deg);
              }

              &::before {
                top: 12px;
                left: 0px;
                transform: rotate(47deg);
              }
            }
          }
        }
      }
    }
  }
}

.header-sticky {
  header.experiencefragment {
    @include mq('large') {
      position: sticky;
      top: -139px;
    }
  }
}
