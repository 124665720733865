#about {
  .breadcrumb {
    .cmp-breadcrumb {
      padding-top: 37px;
      padding-bottom: 46px;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }
  }

  .about-page {
    padding-top: 25px;
    padding-bottom: 30px;

    .image {
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 52px;
    }

    &__content {
      p {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        letter-spacing: -0.3px;
        line-height: var(--emu-semantic-line-heights-narrow-xxxl); //as per live
        font-family: var(--emu-semantic-font-families-heading);
        @include mq('medium') {
          line-height: 27px; //as per live
          font-size: var(--emu-common-font-sizes-wide-medium);
          letter-spacing: 1.3px;
        }
      }
    }
  }
}
