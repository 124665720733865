#article-detail {
  // Utility to add flex to container, size child evenly and add gap of 20 px
  .article--flex-1-by-1 {
    display: flex;
    flex-direction: column;
    gap: 20px;

    img {
      padding: var(--emu-common-spacing-none);
    }

    @include mq('medium') {
      flex-direction: row;
    }

    // To make the the child container of equal width
    > * {
      flex: 1;
    }

    // To show flex direction row from 576px.
    &.flex-row-from-576 {
      @include mq('small') {
        flex-direction: row;
      }
    }
  }

  // Utility to show the container one by one in mobile and to flex 2/3 the first child and other to 1/3
  .article--flex-2-by-3 {
    display: block;

    img {
      padding: var(--emu-common-spacing-none);
    }

    @include mq('small') {
      display: flex;
      flex-direction: row;
    }

    // To make first child to take 66.66% of available width above small screen size
    > div:first-child {
      @include mq('small') {
        flex-basis: 66.66%;
      }
    }

    // Other child will distributed evenly
    > div:not(:first-child) {
      @include mq('small') {
        flex: 1 1 0;
      }
    }
  }

  // Utility to reverser the flex order in mobile device
  .article--flex-mobile-reverse {
    flex-direction: column-reverse;

    @include mq('medium') {
      flex-direction: row;
    }
  }

  // Utility to have text float from one container to another in a flexbox
  .article--floating-text-layout {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include mq('medium-plus') {
      display: block;
    }

    img {
      padding: var(--emu-common-spacing-none);
      width: 100%;
      height: auto;
      max-width: 100%;

      @include mq('medium-plus') {
        width: auto;
      }
    }

    // First child should be image component
    > div:first-child {
      @include mq('medium-plus') {
        float: right;
        max-width: 40%; // To match live site
        margin-left: 25px;
        margin-bottom: 25px;
      }
    }

    // Clear fix for float
    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  // Utility to show a description beneath the image
  .article--image-with-description {
    padding-left: 17.5px;
    padding-right: 17.5px;

    img {
      padding: var(--emu-common-spacing-none);
    }

    p {
      margin-top: 7px;
      margin-bottom: 15px;
      margin-right: var(--emu-common-spacing-none);
      margin-left: var(--emu-common-spacing-none);
      line-height: 17px;
      font-size: 11px;
      font-family: var(--emu-semantic-font-families-mono);
      color: var(--emu-semantic-colors-primary-dark-gray-700);

      @include mq('tablet') {
        letter-spacing: var(--emu-common-spacing-xxs);
        line-height: 16px;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }

  // Utility to remove gap
  .no-gap {
    gap: var(--emu-common-spacing-none);
  }

  // Utility to add gap of 35px on desktop
  .gap-md-35 {
    @include mq('medium') {
      gap: 35px;
    }
  }

  // Utility to add flex none for spacer
  .spacer-flex-none {
    .spacer {
      flex: none;
    }
  }
}
