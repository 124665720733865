footer.experiencefragment {
  display: block;
  color: var(--emu-common-colors-white);
  background-color: var(--emu-semantic-colors-secondary-brown-300);
  padding-top: 20px;
  padding-bottom: 5px;

  @include mq('medium') {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 39px;
  }

  @include mq('large-plus') {
    padding-left: 45px;
    padding-bottom: 20px;
    padding-right: 45px;
  }

  .footer-content {
    max-width: 1440px; // from live site
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 17.5px;
    padding-right: 17.5px;
    padding-top: 26px;
    padding-bottom: 15px;
    background-color: inherit;

    @include mq('medium') {
      padding-bottom: var(--emu-common-spacing-medium);
    }

    @include mq('tablet') {
      padding-top: 30px;
    }

    @include mq('large-plus') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    &__row {
      padding: var(--emu-common-spacing-none);
      margin: var(--emu-common-spacing-none);
      color: inherit;
      background-color: inherit;

      @include mq('medium') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      @include mq('tablet') {
        width: 100%;
      }

      @include mq('large') {
        margin-top: -4px; //needed to match live
      }

      &--sub-menu-item {
        @include mq('tablet') {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        > div:last-child {
          @include mq('tablet') {
            width: 70%;
          }

          @include mq('large-plus') {
            width: 75%;
          }
        }

        @include mq('large-plus') {
          align-items: flex-start;
        }
      }

      &--menu-icon {
        display: table;
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          margin-left: var(--emu-common-spacing-none);
        }
      }
    }

    &__logo {
      img {
        max-width: 204px; // needed to match design
        max-height: 52px; // needed to match design
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
        display: none;

        @include mq('medium') {
          margin-left: var(--emu-common-spacing-none);
          margin-top: var(--emu-common-spacing-small);
          margin-bottom: 37px;
          display: block;
        }

        @include mq('tablet') {
          margin-bottom: 17px;
          margin-top: var(--emu-common-spacing-none);
        }
      }
    }

    &__logo-bottom {
      img {
        max-width: 204px; // needed to match design
        max-height: 52px; // needed to match design
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        margin-bottom: 17px;

        @include mq('medium') {
          display: none;
        }
      }
    }

    &__icon {
      width: var(--emu-common-sizing-medium); // needed to match design
      height: var(--emu-common-sizing-medium); // needed to match design
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__icon-row {
      background-color: var(--emu-common-colors-transparent);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      margin-top: 13px;
      margin-left: -10px; //needed to match live site
      padding-bottom: var(--emu-common-spacing-xxs);
      @include mq('medium') {
        justify-content: left;
        margin-bottom: 10px;
        margin-left: var(--emu-common-spacing-none);
      }

      @include mq('tablet') {
        margin-bottom: var(--emu-common-spacing-none);
      }

      @include mq('large-plus') {
        // to match live site
        margin-bottom: -10px;
      }
    }

    &__title {
      margin-top: 15px;
      margin-bottom: 25px;

      @include mq('medium') {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      > h5 {
        color: var(--emu-common-colors-white);
        font-weight: var(--emu-common-font-weight-regular);
        font-size: 14px;
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        margin: var(--emu-common-spacing-none);
        letter-spacing: 0.7px;
        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-narrow-xl);
          letter-spacing: normal;
        }
      }
    }

    &__body {
      &--keywords {
        margin-bottom: 55px;

        @include mq('medium') {
          margin-bottom: 52px;
        }

        > ul {
          display: flex;
          flex-wrap: wrap;
          margin: var(--emu-common-spacing-none);
          padding: var(--emu-common-spacing-none);
          list-style-type: none;
          gap: 11px 10px;
          justify-content: flex-start;
          align-items: center;

          @include mq('medium') {
            gap: var(--emu-common-spacing-small) 10px;
          }

          li {
            > a {
              display: block;
              width: 100%;
              text-decoration: none;
              color: var(--emu-semantic-colors-primary-dark-gray-100);
              font-size: var(--emu-common-font-sizes-narrow-medium);
              letter-spacing: 0.3px;
              line-height: 1;
              user-select: none;
              font-family: var(--emu-semantic-font-families-heading);
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 13px;
              padding-bottom: 13px;
              margin-bottom: var(--emu-common-spacing-xxs);
              background-color: var(
                --emu-semantic-colors-primary-light-gray-200
              );
              border-radius: var(--emu-common-border-radius-xs);

              @include mq('medium') {
                font-size: 14px;
                line-height: 14px;
                letter-spacing: 0.35px;
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 20px;
                padding-bottom: 21px;
              }

              &:hover,
              &:focus {
                text-decoration: underline;
              }
            }
          }
        }
      }

      &--menu {
        float: left;
        width: 100%;
        border-bottom: none;
        margin-bottom: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        background-color: var(--emu-semantic-colors-secondary-brown-300);

        @include mq('medium') {
          border-bottom: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-white);
          margin-bottom: 50px;
          padding-bottom: 35px;
        }

        @include mq('tablet') {
          margin-bottom: 58px;
          padding-bottom: 45px;
        }

        @include mq('large') {
          margin-bottom: 62px;
        }

        > ul {
          list-style: none;
          display: block;
          width: 100%;
          border-top: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-white);
          padding: var(--emu-common-spacing-none);
          margin-top: 11px;
          @include mq('medium') {
            margin-top: var(--emu-common-spacing-small);
            padding-left: var(--emu-common-spacing-none);
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: var(--emu-common-spacing-medium);
            border-top: none;
            margin-bottom: var(--emu-common-spacing-none);
            flex-wrap: wrap;
            gap: 20px;
          }
          @include mq('tablet') {
            gap: var(--emu-common-spacing-none);
          }

          > li:last-child {
            a {
              white-space: pre;
            }
          }

          li {
            border-bottom: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-white);
            padding-bottom: 14px;
            padding-right: var(--emu-common-spacing-none);
            padding-left: 15px;
            margin-top: 12px;

            @include mq('medium') {
              border-bottom: none;
              padding-left: var(--emu-common-spacing-none);
              text-align: center;
              line-height: 20px;
              padding-bottom: var(--emu-common-spacing-none);
              margin-top: var(--emu-common-spacing-none);
            }

            @include mq('tablet') {
              margin-left: 40px;
              text-align: right;
              padding-bottom: 3px;
              line-height: 23px;
              margin-right: 15px;
              margin-top: 12px;
            }

            @include mq('large') {
              margin-right: var(--emu-common-spacing-none);
            }

            &:first-child {
              margin-left: var(--emu-common-spacing-none);
            }

            > a {
              color: var(--emu-common-colors-white);
              text-decoration: none;
              font-size: var(--emu-semantic-font-sizes-narrow-large);
              line-height: 20px;
              letter-spacing: 0.75px;
              font-family: var(--emu-semantic-font-families-heading);

              @include mq('medium') {
                line-height: 22px;
              }

              @include mq('large-plus') {
                font-size: 15px;
              }

              &:hover,
              &:focus {
                text-decoration: underline;
              }
            }
          }
        }
      }

      &--sub-menu {
        ul {
          grid-template-columns: repeat(2, 1fr);
          gap: 10px 12px;
          list-style: none;
          display: grid;
          padding-left: 1px;
          padding-right: 1px;
          align-items: center;

          @include mq('medium') {
            flex-direction: row;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 13px;
            margin-bottom: 20px;
            flex-wrap: wrap;
            gap: 20px;
          }

          @include mq('tablet') {
            gap: var(--emu-common-spacing-none);
            justify-content: flex-end;
            margin-top: -34px; //needed to match live site
          }

          @include mq('large') {
            justify-content: flex-end;
            margin-left: auto;
            margin-top: -22px; //needed to match live site
          }

          // media query needed to match design
          @include mq('1051px') {
            flex: 0 0 100%;
            max-width: 100%;
          }

          li {
            padding-right: var(--emu-common-spacing-none);
            padding-left: 15px;
            margin-top: 15px;
            padding-bottom: 3px;
            line-height: 0;
            text-align: center;

            @include mq('medium') {
              padding-left: var(--emu-common-spacing-none);
              margin-top: var(--emu-common-spacing-none);
              padding-bottom: var(--emu-common-spacing-none);
            }

            @include mq('tablet') {
              margin-top: 15px;
              margin-left: 40px;
              text-align: right;
              padding-bottom: 3px;
            }

            @include mq('large') {
              margin-top: var(--emu-common-spacing-none);
            }

            @include mq('large-plus') {
              margin-top: 15px;
            }

            &:last-child {
              a {
                @include mq('medium') {
                  font-size: 15px;
                }
              }
            }

            a {
              line-height: 20px;
              letter-spacing: 0.75px;
              font-size: var(--emu-semantic-font-sizes-narrow-large);
              text-transform: none;
              text-decoration: none;
              color: var(--emu-common-colors-white);
              font-family: var(--emu-semantic-font-families-heading);

              &:hover {
                text-decoration: underline;
              }

              @include mq('large-plus') {
                font-size: 15px;
              }
            }
          }
        }
      }
    }

    &__bottom-section {
      p {
        text-align: left;
        font-size: 11px;
        letter-spacing: 0.23px;
        line-height: 12px;
        font-weight: var(--emu-common-font-weight-regular);
        color: var(--emu-common-colors-white);
        padding-top: 15px;
        padding-bottom: 51px;
        font-family: var(--emu-semantic-font-families-heading);

        @include mq('medium') {
          margin-top: var(--emu-common-spacing-none);
          font-size: var(--emu-semantic-font-sizes-wide-large);
          line-height: var(--emu-semantic-line-heights-wide-medium);
          letter-spacing: 0.35px;
          padding-bottom: var(--emu-common-spacing-medium);
        }

        @include mq('tablet') {
          padding-top: 31px;
        }
        @include mq('large') {
          padding-top: 25.5px; //needed to match live site
          padding-bottom: 15px;
        }
      }
    }
  }
}
