.aaaem-button {
  &__secondary-outline {
    &.aaaem-button {
      align-items: center;
      border-top-width: var(--emu-semantic-border-width-thin);
      border-bottom-width: var(--emu-semantic-border-width-thin);
    }
  }

  // Sibone page common used btn style
  &__primary-filled {
    font-size: 5.233vw; //as per live
    line-height: 40px;
    width: 92%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: var(--emu-semantic-font-families-extra-noto-sans-jp);
    font-weight: var(--emu-common-font-weight-black);
    max-width: 600px; //as per live
    transition: 0.4s;

    &:hover {
      opacity: 0.7;
    }

    @include mq('medium') {
      padding-left: 10px;
      padding-right: 10px;
      max-width: 350px; //as per live
      font-size: 15px;
      line-height: 20px;
    }

    @include mq('large') {
      max-width: 600px; //as per live
      font-size: var(--emu-semantic-font-sizes-wide-xxxl);
      line-height: normal;
    }

    > span {
      width: 100%;

      .cmp-button__text {
        display: block;
        text-align: center;
        position: relative;
        &::after {
          position: absolute;
          display: block;
          content: '';
          border-bottom: var(--emu-common-border-width-medium) solid
            var(--emu-common-colors-white);
          border-right: var(--emu-common-border-width-medium) solid
            var(--emu-common-colors-white);
          transform: skew(45deg);
          right: 15px;
          top: 38%;
          width: 5.667vw; //as per live
          height: 1.333vw; //as per live

          @include mq('medium') {
            width: 35px; //as per live
            height: 10px;
            right: 14px;
            top: 5%;
          }

          @include mq('large') {
            top: 37%;
            width: 50px; //as per live
          }
        }
      }
    }
  }
}
