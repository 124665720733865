#sibone {
  .cmp-experiencefragment--header {
    position: relative;
    height: auto;
    z-index: var(--emu-common-other-z-index-header);

    @include mq('large') {
      width: 98%; //as per live
      max-width: 1280px; //as per live
      margin-left: auto;
      margin-right: auto;
      float: initial;
    }

    .cmp-container-header {
      padding-left: var(--emu-common-spacing-medium);
      padding-top: 6.5px; // as per live
      padding-bottom: 3.5px; //to match live
      display: flex;
      justify-content: space-between;

      @include mq('large') {
        justify-content: start;
        padding: var(--emu-common-spacing-none);
      }
    }

    .header {
      &__global-navigation {
        display: none;
      }

      &__logo {
        @include mq('large') {
          display: flex;
          align-items: center;
          width: 22%; //as per live
        }
      }

      &__content {
        > div.button {
          position: relative;

          @include mq('large') {
            display: none;
          }
        }
        > div.container {
          @include mq('large') {
            border-width: var(--emu-common-border-width-none);
          }
        }
      }
      &__menu {
        &-btn {
          ul {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
            margin-left: 15px;
            margin-right: 15px;
            padding: var(--emu-common-spacing-none);

            @include mq('large') {
              margin: var(--emu-common-spacing-none);
            }

            li {
              padding-top: 5px;
              padding-bottom: 6.4px; //needed to match live
              padding-left: 5px;
              padding-right: 5px;
              list-style: none;
              border-radius: 28px;
              max-width: 270px; //as per live
              width: 100%;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 15px;

              @include mq('large') {
                padding-top: 11px;
                padding-bottom: 10px;
                margin-bottom: var(--emu-common-spacing-none);
                padding-left: 3px;
                padding-right: 3px;
                max-width: auto;
                border-radius: 20px;
              }

              a {
                font-size: var(--emu-semantic-font-sizes-narrow-xl);
                line-height: 10px;
                color: var(--emu-common-colors-white);
                text-decoration: none;
                font-weight: var(--emu-common-font-weight-bold);
                font-family: var(
                  --emu-semantic-font-families-extra-noto-sans-jp
                );
                &:hover {
                  text-decoration: underline;
                  text-decoration-color: var(--emu-common-colors-black);
                  text-decoration-thickness: var(
                    --emu-common-border-width-thin
                  ); //as per live
                  @include mq('large') {
                    text-decoration: none;
                  }
                }
              }
            }

            > li:first-child {
              background-color: var(
                --emu-semantic-colors-primary-dark-blue-500
              );
              @include mq('large') {
                margin-bottom: var(--emu-common-spacing-small);
              }
            }
            > li:last-child {
              background-color: var(
                --emu-semantic-colors-primary-dark-blue-100
              );
            }
          }
        }
      }
    }

    .sb {
      &__logo {
        max-width: 150px; //as per live
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;

        @include mq('large') {
          max-width: 245px; //as per live
          width: 100%;
          padding: var(--emu-common-spacing-none);
        }
      }

      &-header__menu {
        padding-top: 12px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        pointer-events: none;
        width: 100%;
        top: 70px;
        position: fixed;
        left: 0;

        @include mq('large') {
          opacity: 1;
          position: relative;
          display: flex;
          top: 0;
          left: 0;
          padding-top: var(--emu-common-spacing-none);
          pointer-events: all;
        }

        &.js-toggle-on {
          opacity: 1;
          pointer-events: auto;
          background: rgba(255, 255, 255, 0.9); //as per live
          overflow-y: scroll;
          z-index: var(--emu-common-other-z-index-layer);
          height: calc(100vh - 46px); //as per live

          @include mq('large') {
            height: max-content;
          }
        }

        > div.text {
          @include mq('large') {
            display: flex;
            align-items: center;
          }
        }

        &-trigger {
          position: absolute;
          top: 14px;
          right: 8vw; //as per live
          width: 30px; //as per live
          height: 30px; //as per live
          background-color: var(--emu-common-colors-transparent);
          padding: var(--emu-common-spacing-none);

          @include mq('medium') {
            right: 14vw; //as per live
            top: 8px;
          }

          @include mq('large') {
            display: none;
          }

          &.js-toggle-on {
            &::before,
            &::after {
              width: 30px; //as per live
              top: 14px;
            }

            &::before {
              transform: rotate(-45deg);
            }

            &::after {
              transform: rotate(45deg);
            }

            span {
              display: none;
              &::after {
                display: none;
              }
            }
          }

          &::before,
          &::after,
          span {
            width: 100%;
            border-top: var(--emu-common-border-width-medium) solid
              var(--emu-semantic-colors-primary-dark-gray-700);
            transition: 0.6s;
            content: '';
            position: absolute;
          }

          &::after {
            top: 7px;
            width: 80%;
          }
          span {
            width: 60%;
            top: 14px;
            .cmp-button__text {
              top: 5px;
              left: 0;
              width: 100%;
              color: var(--emu-semantic-colors-primary-dark-gray-700);
              font-size: 2.133vw;
              line-height: 1.2;
              font-weight: var(--emu-common-font-weight-regular);
              border-width: var(--emu-common-border-width-none);
            }
          }
        }

        .emu-navigation {
          &__content-wrapper {
            position: relative;

            nav {
              background: var(--emu-common-colors-transparent);

              ul {
                margin-top: 10px;
                margin-bottom: 10px;
                overflow: hidden;

                @include mq('medium') {
                  display: flex;
                  margin-right: 15px;
                  flex-direction: column;
                }

                @include mq('large') {
                  flex-direction: row;
                  margin-top: var(--emu-common-spacing-none);
                  margin-bottom: var(--emu-common-spacing-none);
                }

                > li:last-child {
                  @include mq('large') {
                    border-right: var(--emu-common-border-width-thin) dashed
                      var(--emu-semantic-colors-primary-dark-blue-700);
                    margin-bottom: var(--emu-common-spacing-none);
                  }
                }

                li {
                  padding-top: 15px;
                  padding-bottom: 15px;

                  @include mq('large') {
                    padding: var(--emu-common-spacing-none);
                    width: 186px; //as per live
                    border-left: var(--emu-common-border-width-thin) dashed
                      var(--emu-semantic-colors-primary-dark-blue-700);
                  }

                  a {
                    margin-left: 15px;
                    margin-right: 15px;
                    padding-left: 22px;
                    padding-right: var(--emu-common-spacing-none);
                    display: flex;
                    justify-content: center;

                    @include mq('large') {
                      padding-top: 30px;
                      padding-bottom: 30px;
                      padding-left: var(--emu-common-spacing-small);
                      padding-right: var(--emu-common-spacing-small);
                      margin: var(--emu-common-spacing-none);
                    }

                    &:hover {
                      background: var(--emu-common-colors-transparent);
                      @include mq('large') {
                        opacity: 0.7;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.overflow-hidden {
    overflow: hidden;
    @include mq('large') {
      overflow: auto;
    }
  }

  //over-writing header sticky styles
  &.header-sticky {
    header.experiencefragment {
      @include mq('large') {
        position: static;
        top: 0;
      }
    }
  }
}
