#article-detail {
  table {
    border: var(--emu-common-spacing-none);
    border-spacing: var(--emu-common-spacing-none);
    border-collapse: collapse;
    padding: var(--emu-common-spacing-none);

    td {
      border: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black) !important; // To override inline values added via AEM authoring
      font-weight: var(--emu-common-font-weight-regular);
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      letter-spacing: 0.65px;
      line-height: 17px;
      color: var(--emu-common-colors-black);
      padding: var(--emu-common-sizing-xxs);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: var(--emu-semantic-line-heights-narrow-xxxl);
        letter-spacing: 0.9px;
      }
    }

    th {
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      line-height: 17px;
      letter-spacing: 0.9px;
      border-collapse: collapse;
      color: var(--emu-common-colors-white);
      background-color: var(--emu-semantic-colors-secondary-brown-200);
      color: var(--emu-common-colors-white);
      font-weight: var(--emu-common-font-weight-bold);
      border: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-xxl);
        line-height: 36px;
      }
    }

    sup {
      position: relative;
      font-size: 75%; // To match live site
      line-height: var(--emu-common-spacing-none);
      top: var(--emu-common-spacing-none);
    }
  }
}
