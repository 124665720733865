// These are only styles of a page that exists on author to aid authoring.
#kitchen-sink {
  #article-detail {
    .sink-container {
      border-radius: var(--emu-common-border-radius-small);
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 20px;
      overflow: hidden;
      background-color: #ccc;

      // hiding parsys and removing additional spaces
      .new.newpar {
        padding: var(--emu-common-spacing-none);
        &:last-child {
          display: none;
        }
      }

      &__main > .spacer .spacer-elem,
      &__inner .image + .image {
        &::before {
          content: 'Another Example Below';
          display: block;
          margin-top: 20px !important;
          margin-bottom: 20px !important;
          padding-top: 20px !important;
          padding-bottom: 20px !important;
          font-size: 24px;
          font-weight: var(--emu-common-font-weight-bold);
          text-align: center;
          background-color: #f4f4f4;
          opacity: 0.4;
        }
      }

      &__notes {
        color: var(--emu-semantic-colors-secondary-brown-300);
        margin-top: 25px;
        margin-bottom: 25px;

        p,
        li {
          font-size: 18px;
          line-height: 1.5;
          letter-spacing: normal;
          margin-bottom: 10px;
          padding: var(--emu-common-sizing-none);
        }
      }

      &__grid {
        display: flex;
        gap: 40px;

        > div {
          width: calc(30% - 20px);
          padding: var(--emu-common-spacing-none);

          &:first-child {
            width: calc(70% - 20px);
          }
        }
      }

      &__inner {
        h2,
        h3 {
          padding: var(--emu-common-spacing-none);
          margin-top: var(--emu-common-spacing-medium);
          margin-bottom: var(--emu-common-spacing-medium);
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
          color: var(--emu-semantic-colors-secondary-brown-300);
          letter-spacing: 0;
        }

        h2 {
          border: none;
          font-size: 30px;
          margin-bottom: var(--emu-common-spacing-large);
          text-decoration: underline;
        }

        h3 {
          font-size: 20px;
          background-color: transparent;
        }

        .aaaem-image {
          display: block;
        }

        img {
          padding: var(--emu-common-spacing-none);
          width: auto;
          height: auto;
          max-width: 100%;
        }
      }

      &__disclaimer {
        h2 {
          padding-top: var(--emu-common-spacing-none);
          margin-top: var(--emu-common-spacing-small);
          padding-bottom: var(--emu-common-spacing-medium);
          margin-bottom: var(--emu-common-spacing-medium);
          border: none;
          border-bottom: 5px dashed #aaa;
          font-size: 16px;
          color: #aaa;
          letter-spacing: 0;
        }
      }

      &__main {
        padding: 10px;
        border: 5px solid #aaa;
        background-color: var(--emu-common-colors-white);
      }
    }
  }
}
