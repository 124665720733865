// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: var(--emu-common-spacing-none);
}

.no-x-pad {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

.no-y-pad {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
}

.container--large {
  max-width: 1440px; // value as per live site
  margin-left: auto;
  margin-right: auto;
  padding-right: 17px;
  padding-left: 17px;
}

//Sibone page container max-width
.container--xmd {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

// content related util classes. Used to center buttons mostly.
.content--centered {
  text-align: center;
}

.bg--primary-light-gray-100 {
  background-color: var(--emu-semantic-colors-primary-light-gray-100);
}

// text related sizes and colors
[data-component='text'],
[data-component='title'] {
  &[class*='text--color-'] {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }
  &.text--color-white {
    color: var(--emu-common-colors-white);
  }

  &.text--color-black {
    color: var(--emu-common-colors-black);
  }

  &.text--noto-sans {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    li,
    a,
    span {
      font-family: var(--emu-semantic-font-families-heading);
    }
  }
  &.text--graphik {
    font-family: var(--emu-semantic-font-families-body);
  }
}

.heading-primary,
.heading-secondary {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--emu-semantic-colors-primary-dark-gray-100);
    margin-bottom: 8px;
    margin-top: var(--emu-common-spacing-none);
    font-family: var(--emu-semantic-font-families-body);
  }
}

.heading-primary {
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: var(--emu-semantic-line-heights-narrow-xxl);
    letter-spacing: 2px;
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    font-weight: var(--emu-semantic-font-weight-500);
    padding-bottom: 10px;

    @include mq('medium') {
      line-height: var(--emu-semantic-line-heights-wide-xxl);
      letter-spacing: 2.4px;
      font-size: var(--emu-semantic-font-sizes-wide-xl);
      padding-bottom: 15px;
    }
  }
}

.heading-secondary {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    line-height: var(--emu-semantic-line-heights-narrow-xxl);
    font-weight: var(--emu-common-font-weight-bold);
    letter-spacing: 2px;
    padding-top: 10px;
    padding-bottom: 10px;

    @include mq('medium') {
      font-size: var(--emu-semantic-font-sizes-wide-xxl);
      line-height: var(--emu-semantic-line-heights-wide-xxl);
      letter-spacing: 4.8px;
      padding-bottom: 20px;
      padding-top: 20px;
    }

    @include mq('large-plus') {
      font-size: var(--emu-semantic-font-sizes-wide-xl);
    }
  }
}
