#article-detail {
  // Image para lists content with bigger and small image
  .article-detail__img-content-lists {
    border: 6px solid var(--emu-semantic-colors-secondary-brown-400);
    padding-top: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
    padding-bottom: var(--emu-common-spacing-large);
    padding-left: var(--emu-common-spacing-large);

    @include mq('medium') {
      padding-top: var(--emu-common-spacing-xl);
      padding-right: var(--emu-common-spacing-xl);
      padding-bottom: var(--emu-common-spacing-xl);
      padding-left: var(--emu-common-spacing-xl);
    }

    &.article-detail__img-content-mobile {
      & .image,
      & .aaaem-text p {
        @include mq('767px', 'max-width') {
          margin-left: -15px; //to match live site
        }
      }
    }

    &.article-detail__list-with-heading {
      ul {
        li {
          padding-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    &.article-detail__img-content-lists--less-spacing {
      padding-top: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-large);
      padding-bottom: var(--emu-common-spacing-large);
      padding-left: var(--emu-common-spacing-large);
    }

    &.article-detail__img-content-lists--more-spacing {
      padding-top: 48px; //to match live site
      padding-right: 48px; //to match live site
      padding-bottom: 48px; //to match live site
      padding-left: 48px; //to match live site
    }

    ul {
      padding-left: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-none);
      margin-top: var(--emu-common-spacing-none);
    }

    a {
      color: inherit;
    }

    img {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 25px;
      margin-left: auto;
      margin-right: auto;

      @include mq('medium') {
        padding-top: 25px;
      }
    }

    p,
    li {
      color: var(--emu-semantic-colors-primary-dark-gray-100);
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      line-height: 17px;
      letter-spacing: 0.65px; //as per live

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-xxl);
        line-height: var(--emu-semantic-line-heights-narrow-xxxl);
        letter-spacing: 0.9px; //as per live
      }
    }
  }
}
