#sitemap {
  .sitemap {
    &__breadcrumb {
      padding-top: 25px;
      padding-bottom: 30px;
      margin-bottom: 5px;
      @include mq('medium') {
        margin-bottom: var(--emu-common-spacing-medium);
      }
      .breadcrumb .cmp-breadcrumb {
        padding: var(--emu-common-spacing-none);

        &__list {
          padding-top: 12px;
          padding-bottom: 11px;
          padding-left: var(--emu-common-spacing-none);
          margin: var(--emu-common-spacing-none);

          @include mq('medium') {
            padding-bottom: var(--emu-common-spacing-none);
          }
        }

        &__item {
          font-size: 10px;
          line-height: 22px;
          color: var(--emu-semantic-colors-primary-dark-gray-100);
          padding: var(--emu-common-spacing-none);
          margin: var(--emu-common-spacing-none);

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: 1.5;
          }

          > a {
            color: var(--emu-semantic-colors-primary-dark-gray-100);
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          &:last-child {
            color: var(--emu-semantic-colors-primary-dark-gray-800);

            &::after {
              content: '';
            }
          }
        }
      }
    }

    &__wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 10px;
      @include mq('x-large') {
        max-width: 1200px; // as per live lite
        margin-left: auto;
        margin-right: auto;
      }

      .title:nth-of-type(2) {
        margin-bottom: 30px;
      }

      .title:nth-of-type(9) {
        margin-bottom: 30px;
      }

      .title:nth-of-type(10) {
        .sitemap__subtitle {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    &__title {
      padding-bottom: 3px;

      h1 {
        margin: var(--emu-common-spacing-none);
        font-family: var(--emu-semantic-font-families-body);
        font-weight: 400;
        color: var(--emu-semantic-colors-primary-dark-gray-100);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xxxl);
          line-height: var(--emu-semantic-line-heights-wide-xxxl);
        }
      }
    }

    &__subtitle {
      margin-top: 15px;
      margin-bottom: 18px;

      h2 {
        margin: var(--emu-common-spacing-none);
        font-family: var(--emu-semantic-font-families-heading);
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: 34px;
        font-weight: var(--emu-common-font-weight-black);
        color: var(--emu-semantic-colors-secondary-brown-300);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
        }
        a {
          outline: none;
          text-decoration: none;
          color: inherit;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__list {
      ul {
        margin-top: 20px;
        margin-bottom: 25px;
        list-style: none;
        padding-left: 25px;

        li {
          margin-bottom: 15px;

          a {
            color: var(--emu-semantic-colors-primary-dark-gray-400);
            text-decoration: none;
            font-family: var(--emu-semantic-font-families-heading);
            line-height: 25px;
            @include mq('medium') {
              line-height: 44px;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
