#linkbanner {
  .link-banner {
    .breadcrumb .cmp-breadcrumb {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      padding-top: 22px;
      margin-bottom: var(--emu-common-spacing-none);
      @include mq('medium') {
        margin-bottom: 3px;
      }
    }
    &__title {
      h2 {
        display: block;
        color: var(--emu-semantic-colors-primary-dark-gray-100);
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        letter-spacing: 1.8px;
        line-height: 32px;
        padding-top: 5px;
        padding-bottom: 15px;
        font-family: var(--emu-semantic-font-families-body);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-small);

        @include mq('tablet') {
          line-height: var(--emu-semantic-line-heights-wide-xxl);
          font-size: 32px;
          letter-spacing: var(--emu-common-spacing-none);
          padding-top: 50px;
          padding-bottom: 5px;
        }
      }
    }
    &__main-text {
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        letter-spacing: 0.65px;
        line-height: 26px;
        margin-bottom: var(--emu-common-spacing-medium);
        color: var(--emu-semantic-colors-primary-dark-gray-100);
        font-family: var(--emu-semantic-font-families-heading);
        @include mq('tablet') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          letter-spacing: 0.9px;
          line-height: 36px;
        }
      }
    }
    &__text {
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        letter-spacing: 0.65px;
        line-height: 26px;
        margin-bottom: var(--emu-common-spacing-medium);
        color: var(--emu-semantic-colors-primary-dark-gray-100);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          letter-spacing: 0.9px;
          line-height: 36px;
        }
      }
      &--bottom-content {
        padding-bottom: 15px;
      }
      ul {
        margin-top: 10px;
        padding-left: var(--emu-common-spacing-none);
        list-style: disc;
        list-style-position: inside;

        li {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          letter-spacing: 0.65px;
          line-height: 26px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            letter-spacing: 0.9px;
            line-height: 36px;
          }
        }
      }
    }

    &__site-section {
      padding-bottom: var(--emu-common-spacing-xs);
      padding-top: var(--emu-common-spacing-none);

      @include mq('tablet') {
        padding-top: 25px;
      }
    }

    &__agreement-title {
      h2 {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        font-family: var(--emu-semantic-font-families-body);
        letter-spacing: 1px;
        line-height: 21px;
        border-top: var(--emu-common-border-radius-xs) solid
          var(--emu-semantic-colors-secondary-brown-400);
        border-bottom: var(--emu-common-border-radius-xs) solid
          var(--emu-semantic-colors-secondary-brown-400);
        padding: 10px;
        margin-bottom: 15px;

        @include mq('tablet') {
          line-height: 27px;
          font-size: 20px;
          letter-spacing: 1px;
          padding: 15px;
          margin: 20px var(--emu-common-spacing-none);
        }
      }
    }

    &__img-title {
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        font-family: var(--emu-semantic-font-families-body);
        padding-bottom: var(--emu-common-spacing-medium);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xl);
        }
      }
    }

    &__img-container {
      @include mq('small') {
        display: grid;
        grid-template-columns: auto auto;
        gap: 35px;
      }
    }

    &__content-box {
      border: var(--emu-common-border-width-thin) solid #999; // used border color only onetime
      padding: 10px;
      word-break: break-all;
      margin-top: 19px;
      font-size: var(--emu-semantic-font-sizes-wide-large);
      margin-bottom: 50px;
      width: 300px; // needed to match live site
      p {
        font-family: var(--emu-semantic-font-families-heading);
        line-height: 21px;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
      }
      @include mq('small') {
        width: auto;
      }

      // content box width is not adjustable. mq needed to match design
      @include mq('680px') {
        width: 300px; // needed to match live site
      }
    }

    &__img-container {
      padding-bottom: 40px;
      @include mq('medium') {
        padding-bottom: 21px;
      }
    }
  }
}
