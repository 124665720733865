#search {
  [data-component='article-card-list'] {
    &.article-card-list {
      .article-card-list {
        &__header-title {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 25px;

          @include mq('medium') {
            margin-top: 20px;
            margin-bottom: 35px;
          }
        }

        &__action-wrapper {
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('medium') {
            margin-bottom: 20px;
          }
        }

        &__card {
          &-text {
            @include mq('medium') {
              display: block;
              margin-bottom: 13px;
            }

            @include mq('large-plus') {
              margin-bottom: 5px;
            }

            h4 {
              margin-bottom: 5px;
            }
          }

          &-tag-wrapper {
            .article-card-list__card-tag-link {
              padding-right: 9px;
            }
          }

          &.article-card-list__card--dynamically-appended {
            .article-card-list__card-tag-wrapper {
              .article-card-list__card-tag-link {
                @include mq('medium') {
                  padding-right: 12px;
                  letter-spacing: 0.7px;
                }
              }
            }
          }
        }
      }
    }
  }
}
