
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #FFFFFF !default;
$emu-semantic-colors-surface-dark: #000000 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.5) !default;
$emu-semantic-colors-actions-primary-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-dark: #CC0700 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #FFFFFF !default;
$emu-semantic-colors-text-light: #000000 !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-colors-primary-dark-gray-100: #222222 !default;
$emu-semantic-colors-primary-dark-gray-200: #323232 !default;
$emu-semantic-colors-primary-dark-gray-300: #696969 !default;
$emu-semantic-colors-primary-dark-gray-400: #333333 !default;
$emu-semantic-colors-primary-dark-gray-500: #000000 !default;
$emu-semantic-colors-primary-dark-gray-600: #252525 !default;
$emu-semantic-colors-primary-dark-gray-700: #777777 !default;
$emu-semantic-colors-primary-dark-gray-800: #6C757D !default;
$emu-semantic-colors-primary-light-gray-100: #F9F6F5 !default;
$emu-semantic-colors-primary-light-gray-200: #E0E0E0 !default;
$emu-semantic-colors-primary-light-gray-300: #F6F6F6 !default;
$emu-semantic-colors-primary-light-gray-400: #F4F4F4 !default;
$emu-semantic-colors-primary-light-gray-500: #c7c7c7 !default;
$emu-semantic-colors-primary-light-gray-600: #C1C1C1 !default;
$emu-semantic-colors-primary-light-gray-700: #DDDDDD !default;
$emu-semantic-colors-primary-light-gray-800: #EDF0F5 !default;
$emu-semantic-colors-primary-light-gray-900: #666666 !default;
$emu-semantic-colors-primary-dark-blue-100: #005eb8 !default;
$emu-semantic-colors-primary-dark-blue-200: #002554 !default;
$emu-semantic-colors-primary-dark-blue-300: #1760B4 !default;
$emu-semantic-colors-primary-dark-blue-400: #2C70BA !default;
$emu-semantic-colors-primary-dark-blue-500: #00A9E0 !default;
$emu-semantic-colors-primary-dark-blue-600: #C8E9F6 !default;
$emu-semantic-colors-primary-dark-blue-700: #CCD8EB !default;
$emu-semantic-colors-secondary-brown-100: #925C54 !default;
$emu-semantic-colors-secondary-brown-200: #C39C90 !default;
$emu-semantic-colors-secondary-brown-300: #452929 !default;
$emu-semantic-colors-secondary-brown-400: #F6E6E0 !default;
$emu-semantic-font-weight-500: 500 !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-font-families-body: 'Graphik', 'NeueHaasUnicaW1G-Regular', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif' !default;
$emu-semantic-font-families-heading: 'Noto Sans',sans-serif !default;
$emu-semantic-font-families-mono: 'Noto Sans',sans-serif !default;
$emu-semantic-font-families-extra-noto-sans-jp: 'Noto Sans JP',sans-serif !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-horizontal: 0px !default;
$emu-semantic-spacing-vertical: 0px !default;
$emu-semantic-spacing-one-line-height-wide: 0px !default;
$emu-semantic-spacing-one-line-height-narrow: 0px !default;
$emu-semantic-font-sizes-narrow-medium: 16px !default;
$emu-semantic-font-sizes-narrow-large: 13px !default;
$emu-semantic-font-sizes-narrow-xl: 18px !default;
$emu-semantic-font-sizes-narrow-xxl: 20px !default;
$emu-semantic-font-sizes-narrow-xxxl: 32px !default;
$emu-semantic-font-sizes-wide-medium: 16px !default;
$emu-semantic-font-sizes-wide-large: 14px !default;
$emu-semantic-font-sizes-wide-xl: 24px !default;
$emu-semantic-font-sizes-wide-xxl: 18px !default;
$emu-semantic-font-sizes-wide-xxxl: 42px !default;
$emu-semantic-line-heights-narrow-medium: 19px !default;
$emu-semantic-line-heights-narrow-large: 24px !default;
$emu-semantic-line-heights-narrow-xl: 24px !default;
$emu-semantic-line-heights-narrow-xxl: 27px !default;
$emu-semantic-line-heights-narrow-xxxl: 36px !default;
$emu-semantic-line-heights-wide-medium: 19px !default;
$emu-semantic-line-heights-wide-large: 26px !default;
$emu-semantic-line-heights-wide-xl: 30px !default;
$emu-semantic-line-heights-wide-xxl: 32px !default;
$emu-semantic-line-heights-wide-xxxl: 50px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 576px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-medium-plus: 769px !default;
$emu-semantic-sizing-breakpoints-tablet: 992px !default;
$emu-semantic-sizing-breakpoints-large: 1024px !default;
$emu-semantic-sizing-breakpoints-large-plus: 1025px !default;
$emu-semantic-sizing-breakpoints-x-large: 1200px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1440px !default;
$emu-semantic-sizing-one-line-height-wide: 19px !default;
$emu-semantic-sizing-one-line-height-narrow: 19px !default;
$emu-semantic-typography-narrow-headings-xxxl: 700 32px/36px 'Noto Sans',sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 700 20px/27px 'Noto Sans',sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 700 18px/24px 'Noto Sans',sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 700 13px/24px 'Noto Sans',sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 700 16px/19px 'Noto Sans',sans-serif !default;
$emu-semantic-typography-narrow-body-regular: 400 16/24 'Graphik', 'NeueHaasUnicaW1G-Regular', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif' !default;
$emu-semantic-typography-wide-headings-xxxl: 700 42px/50px 'Noto Sans',sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 700 18px/32px 'Noto Sans',sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 700 24px/30px 'Noto Sans',sans-serif !default;
$emu-semantic-typography-wide-headings-large: 700 14px/26px 'Noto Sans',sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 700 16px/19px 'Noto Sans',sans-serif !default;
$emu-semantic-typography-wide-body-regular: 400 16/24 'Graphik', 'NeueHaasUnicaW1G-Regular', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif' !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        'darkGray': (
          '100': $emu-semantic-colors-primary-dark-gray-100,
          '200': $emu-semantic-colors-primary-dark-gray-200,
          '300': $emu-semantic-colors-primary-dark-gray-300,
          '400': $emu-semantic-colors-primary-dark-gray-400,
          '500': $emu-semantic-colors-primary-dark-gray-500,
          '600': $emu-semantic-colors-primary-dark-gray-600,
          '700': $emu-semantic-colors-primary-dark-gray-700,
          '800': $emu-semantic-colors-primary-dark-gray-800
        ),
        'lightGray': (
          '100': $emu-semantic-colors-primary-light-gray-100,
          '200': $emu-semantic-colors-primary-light-gray-200,
          '300': $emu-semantic-colors-primary-light-gray-300,
          '400': $emu-semantic-colors-primary-light-gray-400,
          '500': $emu-semantic-colors-primary-light-gray-500,
          '600': $emu-semantic-colors-primary-light-gray-600,
          '700': $emu-semantic-colors-primary-light-gray-700,
          '800': $emu-semantic-colors-primary-light-gray-800,
          '900': $emu-semantic-colors-primary-light-gray-900
        ),
        'darkBlue': (
          '100': $emu-semantic-colors-primary-dark-blue-100,
          '200': $emu-semantic-colors-primary-dark-blue-200,
          '300': $emu-semantic-colors-primary-dark-blue-300,
          '400': $emu-semantic-colors-primary-dark-blue-400,
          '500': $emu-semantic-colors-primary-dark-blue-500,
          '600': $emu-semantic-colors-primary-dark-blue-600,
          '700': $emu-semantic-colors-primary-dark-blue-700
        )
      ),
      'secondary': (
        'brown': (
          '100': $emu-semantic-colors-secondary-brown-100,
          '200': $emu-semantic-colors-secondary-brown-200,
          '300': $emu-semantic-colors-secondary-brown-300,
          '400': $emu-semantic-colors-secondary-brown-400
        )
      )
    ),
    'fontWeight': (
      '500': $emu-semantic-font-weight-500,
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'mono': $emu-semantic-font-families-mono,
      'extra': (
        'NotoSansJp': $emu-semantic-font-families-extra-noto-sans-jp
      )
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'horizontal': $emu-semantic-spacing-horizontal,
      'vertical': $emu-semantic-spacing-vertical,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'medium': $emu-semantic-line-heights-narrow-medium,
        'large': $emu-semantic-line-heights-narrow-large,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-line-heights-wide-medium,
        'large': $emu-semantic-line-heights-wide-large,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'medium-plus': $emu-semantic-sizing-breakpoints-medium-plus,
        'tablet': $emu-semantic-sizing-breakpoints-tablet,
        'large': $emu-semantic-sizing-breakpoints-large,
        'large-plus': $emu-semantic-sizing-breakpoints-large-plus,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'wide': $emu-semantic-sizing-one-line-height-wide,
        'narrow': $emu-semantic-sizing-one-line-height-narrow
      )
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular
        )
      )
    )
  )
);
