.article-details {
  &__pagination {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
  }

  &__pagination-button {
    a {
      margin-right: 20px;
      margin-left: 20px;
      outline: none;
      text-decoration: none;
      font-weight: var(--emu-common-font-weight-regular);
      line-height: 20px;
      font-size: 14px;
      letter-spacing: var(--emu-common-spacing-xxs);
      padding: var(--emu-common-spacing-none);
      color: var(--emu-semantic-colors-primary-dark-gray-400);
      font-family: var(--emu-semantic-font-families-mono);
      margin-right: 20px;
      margin-left: 20px;

      &:hover {
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-primary-dark-gray-100);
      }
    }

    &--previous {
      margin-right: var(--emu-common-spacing-none);

      @include mq('medium') {
        margin-right: 40px;
      }
    }

    &--next {
      margin-left: var(--emu-common-spacing-none);

      @include mq('medium') {
        margin-left: 40px;
      }
    }
  }

  &__pagination-icon {
    &--left {
      margin-right: 22px;
    }

    &--right {
      margin-left: 16px;
    }
  }
}
