.breadcrumb .cmp-breadcrumb {
  padding-left: 17.5px; // to match live site
  padding-right: 17.5px; // to match live site
  padding-top: 27px;
  padding-bottom: var(--emu-common-spacing-medium);

  &__list {
    padding: var(--emu-common-spacing-none);
    margin: var(--emu-common-spacing-none);
    line-height: 22px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    font-size: 10px;
    font-weight: var(--emu-semantic-colors-primary-dark-gray-100);
    line-height: 22px; // to match live site
    color: var(--emu-semantic-colors-primary-dark-gray-100);
    padding: var(--emu-common-spacing-none);
    margin: var(--emu-common-spacing-none);

    @include mq('medium') {
      font-size: 1rem; // to match live site
      line-height: 1.5; // to match live site
    }

    > a {
      color: var(--emu-semantic-colors-primary-dark-gray-100);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    &::after {
      content: '/';
      font-size: 10px;
      margin-left: 5px;
      margin-right: 5px;
      color: var(--emu-semantic-colors-primary-dark-gray-100);
      font-weight: var(--emu-semantic-colors-primary-dark-gray-100);
      font-family: var(--emu-semantic-font-families-heading);

      @include mq('medium') {
        margin-left: 10px;
        margin-right: 10px;
        font-size: var(--emu-semantic-font-sizes-wide-medium);
      }
    }

    &:last-child {
      color: var(--emu-semantic-colors-primary-dark-gray-800);

      &::after {
        content: '';
      }
    }
  }
}

// Article page breadcrumb
.categorypage {
  .breadcrumb .cmp-breadcrumb {
    display: flex;
    padding-left: var(--emu-common-spacing-none); // to match live site
    padding-right: var(--emu-common-spacing-none); // to match live site
    padding-top: 22px;
    padding-bottom: var(--emu-common-spacing-medium);
  }
}
