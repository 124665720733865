#article-detail {
  .social-media {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 55px; // to match live site
    padding-bottom: 30px; // to match live site

    @include mq('medium') {
      padding-top: 45px;
    }

    .aaaem-image img {
      width: 23px; // to match live site
      height: 23px; // to match live site
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      cursor: pointer;
      max-width: unset;
      padding: 0;

      @include mq('medium') {
        width: var(--emu-common-sizing-medium);
        height: var(--emu-common-sizing-medium);
      }
    }
  }
}
