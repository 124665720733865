.aaaem-carousel {
  &__action {
    border-radius: 50%;
    height: 34px; // to match live site
    width: 34px; // to match live site
    bottom: auto;
    top: 50%;
    transform: translateY(-50%) translateY(-20px);

    &:hover {
      background: var(--emu-semantic-colors-primary-dark-gray-400);
      opacity: 0.5;
    }

    @include mq('medium') {
      transform: translateY(-50%) translateY(-7px);
    }

    &-next {
      right: 10px;

      @include mq('medium') {
        right: 25px;
      }

      svg {
        left: 1px;
      }
    }

    &-prev {
      left: 10px;

      @include mq('medium') {
        left: 25px;
      }

      svg {
        left: -1px;
      }
    }

    svg {
      position: relative;
      top: -2px;
    }
  }

  &__indicators {
    position: relative;
    top: 6px;
  }
}
