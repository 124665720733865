#article-detail {
  .table-of-content {
    padding-top: 40px; // To match live site
    padding-bottom: 60px; // To match live site

    &__content {
      padding: 15px;
      background: var(--emu-semantic-colors-primary-light-gray-100);

      @include mq('medium') {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 40px;
        padding-right: 40px;
      }

      > p:first-child {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        font-family: var(--emu-semantic-font-families-heading);
        display: inline-block;
        line-height: 31px; // To match live site
        letter-spacing: 1.15px;
        font-weight: var(--emu-common-font-weight-bold);
        display: flex;
        align-items: center;
        gap: 5%; // To match live site
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('medium') {
          font-size: 23px;
        }

        &::after {
          content: '';
          position: relative;
          flex-grow: 1;
          height: var(--emu-common-sizing-xxs);
          background-color: var(--emu-semantic-colors-secondary-brown-400);
        }
      }

      ol,
      ul {
        padding-top: 3px;
        padding-left: 5px;
        margin-top: var(--emu-common-spacing-none);
        list-style-type: none;

        @include mq('medium') {
          margin-left: 34px;
        }

        li {
          padding-bottom: var(--emu-common-spacing-none);
        }

        > li {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-narrow-xxl);
          letter-spacing: 0.65px; // To match live site
          font-weight: var(--emu-common-font-weight-light);
          font-family: var(--emu-semantic-font-families-heading);
          margin-top: 20px;

          @include mq('medium') {
            font-size: 20px;
            letter-spacing: normal;
          }

          a {
            text-decoration: none;
            color: var(--emu-semantic-colors-primary-dark-gray-100);
            font-family: var(--emu-semantic-font-families-heading);

            &:hover {
              text-decoration: underline;
            }
          }

          ol,
          ul {
            padding-top: var(--emu-common-sizing-xxs);
            margin-left: var(--emu-common-sizing-xs);

            @include mq('medium') {
              margin-left: 23px;
            }

            li {
              font-size: var(--emu-semantic-font-sizes-narrow-large);
              line-height: 17px;
              letter-spacing: 0.65px; // To match live site
              margin-bottom: 5px;
              margin-top: 17px;
              margin-bottom: 21px;
              font-family: var(--emu-semantic-font-families-heading);

              @include mq('medium') {
                font-size: 20px;
                letter-spacing: normal;
                margin-top: 18px;
                line-height: 45px; // To match live site
              }

              a {
                text-decoration: none;
                color: var(--emu-semantic-colors-primary-dark-gray-100);
                font-family: var(--emu-semantic-font-families-heading);

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
      // Utility class for table content
      &--main-text {
        ul {
          li {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            a {
              font-size: var(--emu-semantic-font-sizes-narrow-large);
              letter-spacing: 0.65px;
              font-family: var(--emu-semantic-font-families-heading);
              @include mq('medium') {
                font-size: 20px;
                line-height: 27px;
                letter-spacing: normal;
              }
            }
          }
        }
      }
    }
  }
}
