#terms-and-conditions-clinic-locator {
  .terms-and-conditions-page {
    &__title {
      h2 {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        letter-spacing: 1px;
        line-height: 21px;
        border-top: var(--emu-common-border-radius-xs) solid
          var(--emu-semantic-colors-secondary-brown-400);
        border-bottom: var(--emu-common-border-radius-xs) solid
          var(--emu-semantic-colors-secondary-brown-400);
        font-weight: var(--emu-common-font-weight-bold);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 15px;
        margin-top: 25px;
        @include mq('tablet') {
          line-height: 27px;
          font-size: 20px;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 15px;
          padding-right: 15px;
          margin-top: 45px;
          margin-right: var(--emu-common-spacing-none);
          margin-bottom: 20px;
          margin-left: var(--emu-common-spacing-none);
        }
      }
    }

    &__content {
      p {
        margin-bottom: var(--emu-common-spacing-medium);
        font-family: var(--emu-semantic-font-families-heading);
      }
    }
  }
}
