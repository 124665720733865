#error {
  .error-page {
    padding-top: 25px;
    padding-bottom: 25px;

    &__content {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      @include mq('small') {
        max-width: 50%;
      }
      p {
        line-height: 34px;
        font-weight: var(--emu-common-font-weight-regular);
        margin-top: var(--emu-semantic-spacing-none);
        margin-bottom: var(--emu-common-spacing-small);
        letter-spacing: 0;

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
        }
      }
    }
  }
}
