#article-detail {
  // Heading 2 start //
  // Heading 2 with border on top and bottom of text
  h2 {
    border-top: var(--emu-common-border-radius-xs) solid
      var(--emu-semantic-colors-secondary-brown-400);
    border-bottom: var(--emu-common-border-radius-xs) solid
      var(--emu-semantic-colors-secondary-brown-400);
    letter-spacing: 1px;
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-top: 10px;
    margin-right: var(--emu-common-spacing-none);
    margin-bottom: 15px;
    margin-left: var(--emu-common-spacing-none);
    @include mq('medium') {
      padding-top: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      margin-top: 20px;
      font-size: var(--emu-semantic-font-sizes-narrow-xxl);
      margin-bottom: 20px;
    }
  }
  // Heading 2 end //

  // Heading 3 start //
  // Heading 3 with no border and with pink background
  h3 {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    background-color: var(--emu-semantic-colors-secondary-brown-400);
    margin-top: 15px;
    margin-right: var(--emu-common-spacing-none);
    margin-bottom: 15px;
    margin-left: var(--emu-common-spacing-none);
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    letter-spacing: 0.65px;
    line-height: 17px;
    @include mq('medium') {
      font-size: 20px;
      letter-spacing: 1px;
      line-height: 27px;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
    }
  }
  // Heading 3 end //

  // Heading 4 variant 1 start //
  // Heading 4 with bold text and a black border at bottom followed by a pink border with some offset
  h4 {
    margin: var(--emu-common-spacing-none);
    position: relative;
    padding-bottom: 10px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--emu-semantic-colors-secondary-brown-400);
    margin-bottom: 20px;
    text-indent: 1px;

    @include mq('medium') {
      font-size: 20px;
      line-height: var(--emu-common-line-heights-wide-large);
      letter-spacing: 0.9px; // As per live site.
      text-indent: 2px;
    }

    &::before {
      content: '';
      border-bottom-width: var(--emu-common-border-width-medium);
      border-bottom-style: solid;
      border-bottom-color: var(--emu-semantic-colors-primary-dark-gray-400);
      width: 75px; // As per live site.
      position: absolute;
      top: 32px;
      left: var(--emu-common-spacing-none);

      @include mq('medium') {
        border-bottom-width: 3px;
        top: var(--emu-common-spacing-large); // To match live site.
      }
    }

    b {
      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-xxl);
        font-family: var(--emu-semantic-font-families-body);
      }
    }
  }
  // Heading 4 variant 1 end //

  // Heading 4 variant 2 start //
  // Heading 4 with leading hyphen before the text start

  .heading-4--leading-hyphen h4,
  h5 {
    line-height: 17px;
    letter-spacing: 0.75px; // As per live site.
    padding: var(--emu-common-spacing-none);
    margin: var(--emu-common-spacing-none);
    border: none;
    margin-bottom: var(--emu-common-spacing-small);

    @include mq('medium') {
      letter-spacing: 1px;
    }

    &::before {
      content: '';
      position: relative;
      top: unset;
      border: none;
      display: inline-block;
      width: 12px; // To match live site
      height: 2px; // To match live site
      background-color: var(--emu-semantic-colors-secondary-brown-400);
      transform: translateY(-3px);
      margin-right: 1.2px; // To match live site

      @include mq('medium') {
        width: 21px;
        height: 3px;
        transform: translateY(-4px);
      }
    }
  }

  // Heading 4 variant 2 end //

  // Paragraph start//
  p {
    font-size: 13px;
    letter-spacing: 0.65px;
    line-height: 17px;
    margin-bottom: 1rem;
    font-family: var(--emu-semantic-font-families-heading);
    @include mq('medium') {
      font-size: 18px;
      letter-spacing: 0.9px;
      line-height: 36px;
    }
    a {
      display: inline-block;
      word-break: break-all;
      font-family: var(--emu-semantic-font-families-body);
    }
    b {
      font-family: var(--emu-semantic-font-families-body);
    }
    sub,
    sup {
      font-family: var(--emu-semantic-font-families-body);
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
    }
    sup {
      top: -0.5em; //to match live
    }
    sub {
      bottom: -0.25em; //to match live
    }
  }
  // Paragraph end//

  // highlighting the text
  .text,
  .title {
    .emphasis {
      background-color: #ffff00; // Used once
    }
  }

  a {
    color: var(--emu-common-colors-black);
    font-family: var(--emu-semantic-font-families-body);
  }

  ul,
  ol {
    padding: 0 0 0 15px;

    li {
      padding-bottom: 10px;
      font-size: 13px;
      letter-spacing: 0.65px;
      line-height: 17px;
      font-family: var(--emu-semantic-font-families-body);

      @include mq('medium') {
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.9px;
      }
    }
  }

  // Paragraph small text start //
  .article--small-text {
    p {
      letter-spacing: 0.65px;
      line-height: 17px;
      margin-bottom: 15px;
      font-size: 12px;
      @include mq('medium') {
        letter-spacing: 0.9px;
        line-height: 32px;
        margin-bottom: 20px;
      }
    }
  }

  .numbered-list {
    p {
      font-family: var(--emu-semantic-font-families-mono);
      letter-spacing: 0.65px; //to match live site
    }

    ol {
      padding-left: 15px; //to match live site
      margin-left: 10px; //to match live site
      li {
        font-family: var(--emu-semantic-font-families-mono);
        margin-bottom: 10px; //to match live site
        padding-bottom: var(--emu-common-spacing-none);
        font-weight: var(--emu-common-font-weight-regular);
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        letter-spacing: 0.65px;
        line-height: 17px; //to match live site
        word-break: break-word;

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          letter-spacing: 0.9px; //to match live site
          line-height: var(--emu-semantic-line-heights-wide-xxl);
        }

        a {
          margin-bottom: var(--emu-common-spacing-medium);
          display: inline;
          @include mq('medium') {
            line-height: 38px; //to match live site
          }
        }
      }
    }
  }

  // Utility to add different color to link
  .article--link-primary p a {
    color: #8064a2; //to match live site
  }

  // Utility for font size 16px
  .article--fs-16-text {
    p {
      font-size: var(--emu-semantic-font-sizes-wide-medium);
      line-height: var(--emu-common-line-heights-wide-medium);
      font-weight: var(--emu-common-font-weight-regular);
      font-family: var(--emu-semantic-font-families-heading);
    }
  }

  // Utility for line heights start //
  // Utility to add a line height of 17px
  .article--lh-17 {
    p,
    li {
      line-height: 17px;
    }
  }

  // Utility to add a line height of 26px
  .article--lh-26 {
    p,
    li {
      line-height: 26px;
    }
  }

  // Utility to add a line height of 32px
  .article--lh-32 {
    p,
    li {
      line-height: var(--emu-semantic-font-sizes-narrow-xxxl);
    }
  }

  // Utility to add a line height of 26px for desktop
  .article--lh-md-26 {
    p,
    li {
      @include mq('medium') {
        line-height: var(--emu-semantic-line-heights-wide-large);
      }
    }
  }

  // Utility to add a line height of 32px for desktop
  .article--lh-md-32 {
    p,
    li {
      @include mq('medium') {
        line-height: var(--emu-semantic-line-heights-wide-xxl);
      }
    }
  }

  // Utility to add a line height of 36px for desktop
  .article--lh-md-36 {
    p,
    li {
      @include mq('medium') {
        line-height: 36px;
      }
    }
  }
  // Utility for line heights end //

  // Utility to add margin bottom of 20px for desktop
  .article--text-mb-20 {
    p {
      margin-bottom: 15px;
      @include mq('medium') {
        margin-bottom: 20px;
      }
    }
  }

  // Utility to add margin bottom of 0px across all screen sizes.
  .article--text-mb-none {
    p {
      margin-bottom: var(--emu-common-spacing-none);
    }
  }

  // Utility to change font family of text
  [data-component='text'],
  [data-component='title'] {
    &.text--noto-sans {
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      li,
      a,
      span {
        font-family: var(--emu-semantic-font-families-heading);
      }
    }
    &.text--graphik {
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      li,
      a,
      span {
        font-family: var(--emu-semantic-font-families-body);
      }
    }
  }

  // Utility to add different color to text
  .article--text-grey p {
    color: #7f7f7f; //to match live site
  }
}
