[data-component='article-card-list'] {
  &.article-card-list {
    padding-left: 17.5px; // from live site
    padding-right: 17.5px; // from live site
    padding-top: 25px;
    padding-bottom: 30px;

    .article-card-list {
      &__inner {
        @include mq('medium') {
          margin-right: auto;
          margin-left: auto;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          width: 95%;
        }
      }

      &__header-title {
        margin-bottom: 33px;
        margin-top: 10px;
        letter-spacing: 2px;
        color: var(--emu-semantic-colors-primary-dark-gray-100);

        @include mq('medium') {
          margin-top: 20px;
          margin-bottom: 43px;
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          letter-spacing: 4.8px;
          line-height: var(--emu-semantic-line-heights-wide-xxl);
        }

        @include mq('large-plus') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
        }
      }

      &__card {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        border-bottom: var(--emu-common-spacing-xxs) solid #eedad1; // Color used once
        scroll-margin-top: 60px;
        gap: 15px;
        padding-bottom: 5px;

        @include mq('medium') {
          border-bottom: none;
          display: block;
          scroll-margin-top: 85px;
        }

        // wrapper containing all the styles
        &-wrapper {
          display: grid;
          grid-template-columns: 1fr;
          gap: 15px;

          @include mq('medium') {
            gap: 25px;
            grid-template-columns: repeat(3, 1fr);
          }

          // special styles as per live site
          .article-card-list__card:first-child {
            padding-bottom: var(--emu-common-spacing-none);

            .article-card-list__card-tag-link:last-child {
              margin-bottom: 10px;
            }

            .article-card-list__card-text-wrapper {
              padding-bottom: 10px;

              @include mq('medium') {
                padding-bottom: var(--emu-common-spacing-none);
              }
            }
          }
        }

        &-image {
          display: block;
          width: 100%; // To match live site
          max-width: 625px; // To match live site

          &-wrapper {
            width: 115px; // To match live site
            margin-bottom: 10px;

            @include mq('medium') {
              width: 100%;
              display: block;
            }
          }
        }

        &-text {
          text-decoration: none;

          &-wrapper {
            flex: 1;
            min-width: 63%;
          }

          h4 {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: 13px;
            font-weight: var(--emu-common-font-weight-bold);
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            letter-spacing: 0.65px; // to match live site
            line-height: var(--emu-semantic-line-heights-narrow-large);
            color: var(--emu-semantic-colors-primary-dark-gray-100);

            // these next 4 lines are from live site
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
              line-height: var(--emu-semantic-line-heights-wide-large);
              letter-spacing: 0.75px; // To match live site
              min-height: 80px; // To match live site
            }

            @include mq('large-plus') {
              min-height: 110px; // To match live site
              letter-spacing: 0.9px; // To match live site
              font-size: var(--emu-semantic-font-sizes-wide-xxl);
              line-height: 36px;
              margin-bottom: 5px;
            }
          }
        }

        &-tag {
          &-link {
            font-weight: var(--emu-common-font-weight-regular);
            text-decoration: none;
            margin-bottom: 5px;
            font-size: var(--emu-common-font-sizes-narrow-medium);
            line-height: 16px;
            letter-spacing: 0.55px;
            display: inline-block;
            padding-right: 9px;
            color: var(--emu-semantic-colors-primary-dark-gray-400);
            font-family: var(--emu-semantic-font-families-heading);

            &:last-child {
              margin-bottom: 15px;
            }

            @include mq('medium') {
              font-size: 11px;

              &:last-child {
                margin-bottom: 5px;
              }
            }

            @include mq('large-plus') {
              font-size: 12px;
            }
          }
        }

        &-text:hover,
        &-tag-link:hover {
          text-decoration: underline;
          text-decoration-color: var(
            --emu-semantic-colors-primary-dark-gray-100
          );
        }
      }

      &__action {
        letter-spacing: 1px;
        padding-top: 10px;
        padding-bottom: 10px;

        &-wrapper {
          margin-top: 20px;

          @include mq('medium') {
            margin-top: 30px;
          }

          &--hide {
            .cmp-button {
              display: none;
            }
          }
        }

        .plus {
          margin-left: 15px;
          margin-right: var(--emu-common-spacing-none);
          border: var(--emu-common-border-width-thin) solid #f8f8f8; // used only once
          border-radius: 50%;
          padding-top: 3.5px;
          padding-right: 6px;
          padding-bottom: 3.5px;
          padding-left: 6px;
          font-family: var(--emu-semantic-font-families-heading);
          line-height: normal;
        }
      }
    }

    // first article should be full width
    &.article-card-list--scale-first-article {
      // max width media query is needed as these styles should not apply for tablet and above
      @include mq('767px', 'max-width') {
        .article-card-list__card-wrapper {
          .article-card-list__card:first-child {
            display: block;
            padding-bottom: var(--emu-common-spacing-none);

            .article-card-list {
              &__card {
                &-image-wrapper {
                  margin-bottom: 10px;
                  width: auto;
                  display: block;
                }

                &-image {
                  max-width: none;
                }

                &-text-wrapper {
                  padding-bottom: 15px;
                }

                &-text {
                  h4 {
                    margin-bottom: 5px;
                  }
                }

                &-tag-link {
                  font-size: 11px;
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
    }

    // first article should show a black opaque ribbon
    // works in conjunction with `article-card-list--scale-first-article`
    &.article-card-list--highlight-first-article {
      // max width media query is needed as these styles should not apply for tablet and above
      @include mq('767px', 'max-width') {
        overflow: hidden;

        .article-card-list__card-wrapper {
          .article-card-list__card:first-child {
            margin-left: -17.5px;
            margin-right: -17.5px;
            position: relative;
            border: none;

            .article-card-list {
              &__card {
                &-text-wrapper {
                  position: absolute;
                  bottom: 10px;
                  left: 0;
                  width: 100%;
                  background-color: var(
                    --emu-semantic-colors-surface-modal-backdrop
                  );
                  margin-left: var(--emu-common-spacing-none);
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-bottom: var(--emu-common-spacing-none);
                }

                &-text {
                  h4 {
                    color: var(--emu-common-colors-white);
                    margin-top: var(--emu-common-spacing-small);
                    margin-bottom: 13px;
                  }
                }

                &-image {
                  max-width: 625px;
                }

                &-tag-wrapper {
                  margin-bottom: 5px;
                }

                &-tag-link {
                  color: var(--emu-common-colors-white);
                  margin-bottom: 5px;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    // image should be on left and content should be on right
    &.article-card-list--image-first {
      // max width media query is needed as these styles should not apply for tablet and above
      @include mq('767px', 'max-width') {
        .article-card-list__card-wrapper {
          .article-card-list {
            &__card {
              flex-direction: row;
              padding-bottom: 15px;

              &-image-wrapper {
                width: 100px; // value from live site
              }

              &-text {
                h4 {
                  margin-bottom: 5px;
                }
              }

              &-tag-link {
                font-size: 11px;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }

    // articles should show in two column grid in mobile
    &.article-card-list--2-cards-in-row-mobile {
      @include mq('767px', 'max-width') {
        padding: var(--emu-common-spacing-none);
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        .article-card-list {
          &__inner {
            padding-top: 25px;
            padding-bottom: 30px;
          }

          &__header-title {
            margin-top: 10px;
            margin-bottom: 33px;
          }

          &__card-wrapper {
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
          }

          &__card {
            display: flex;
            flex-direction: column;
            border: none;
            padding-bottom: 5px;
            gap: var(--emu-common-spacing-none);

            &-image-wrapper {
              display: block;
              width: 100%;
            }

            &-text-wrapper {
              padding-bottom: var(
                --emu-common-spacing-none
              ) !important; // intentional to increase specificity
            }

            &-tag-link {
              letter-spacing: 0.6px;
              padding-right: 10px;
            }

            &-text {
              font-size: 13px;
              line-height: 21px;

              h4 {
                margin-bottom: 3px;
              }
            }
          }

          &__action-wrapper {
            margin-top: 35px;
            margin-bottom: 20px;

            &--hide {
              margin-top: var(--emu-common-spacing-none);
            }
          }
        }
      }
    }

    // articles should show in 4 column grid in mobile
    &.article-card-list--4-cards-in-row-desktop {
      @include mq('medium') {
        .article-card-list {
          &__card-wrapper {
            grid-template-columns: repeat(4, 1fr);
          }

          &__card {
            &-image-wrapper {
              max-width: 200px;

              @include mq('large-plus') {
                margin-left: 35px;
              }
            }
          }
        }
      }
    }

    // when the article cards does not have any list to show, show error message
    &.article-card-list--has-no-results {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      padding: var(--emu-common-spacing-none);

      @include mq('medium') {
        padding-left: 17.5px;
        padding-right: 17.5px;
        width: 95%;
      }

      .article-card-list__header#article-detail {
        padding: var(--emu-common-spacing-none);

        h2 {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          letter-spacing: 2px;
          word-break: break-all;
          font-weight: var(--emu-semantic-font-weight-bold);
          margin-top: 15px;
          margin-bottom: var(--emu-common-spacing-medium);

          @include mq('medium') {
            margin-top: 25px;
            padding-top: 10px;
            padding-right: 10px;
            padding-left: 10px;
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            line-height: var(--emu-semantic-line-heights-wide-xxl);
            letter-spacing: 2.4px;
            margin-bottom: 24px;
          }

          @include mq('tablet') {
            margin-top: 45px;
            padding: 15px;
            margin-bottom: 29px;
          }
        }
      }

      .no-results-message {
        text-align: center;
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.75px;
        margin-bottom: 26px;

        @include mq('medium') {
          margin-bottom: 52px;
          letter-spacing: 0.9px;
        }

        p {
          margin: var(--emu-common-spacing-xxs);
        }
      }
    }

    // when the articles has show more, the spacings need to be adjusted.
    // Ideally this could have been a separate setting in the dialog,
    // but the changes are minor and does not need a different implementation
    &.article-card-list--has-show-more {
      .article-card-list {
        &__card {
          &--dynamically-appended {
            @include mq('medium') {
              padding-bottom: var(--emu-common-spacing-none);
            }

            .article-card-list__card-tag-link {
              margin-bottom: 5px;
              padding-right: 12px;
              letter-spacing: 0.65px;

              &:last-child {
                margin-bottom: 10px;
              }
            }
          }

          &-text {
            h4 {
              @include mq('medium') {
                margin-bottom: 3px;
              }
            }
          }
        }

        &__action-wrapper {
          @include mq('medium') {
            margin-top: 45px; //to match live
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
