.article-banner-card {
  @include mq('large-plus') {
    width: 97%;
  }

  // needed to make it look good
  @include mq('2000px') {
    width: 100%;
  }

  &__link {
    display: none;
  }

  &__section {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: 11px;
    background: var(--emu-semantic-colors-primary-light-gray-400);

    @include mq('medium') {
      padding-top: 20px;
    }

    @include mq('medium-plus') {
      padding-top: 45px;
    }

    @include mq('large-plus') {
      padding-top: 25px;
    }
  }

  &__content-section {
    background: linear-gradient(
      hsla(0, 0%, 100%, 0.5),
      var(--emu-common-colors-white)
    );
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: var(--emu-common-spacing-none);
    min-height: 70px; // needed to match live site
    padding-bottom: 10px;

    //  to match live site
    @include mq('medium-plus') {
      min-height: 100px; // to match live site
      padding-bottom: 20px;
    }

    @include mq('large-plus') {
      width: 97%;
    }

    // mq needed to make it look better
    @include mq('2000px') {
      width: 100%;
    }
  }

  &__tag-list {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    list-style: none;
    padding-left: var(--emu-common-spacing-none);

    li {
      list-style: none;
    }

    a {
      margin-right: 15px;
      color: var(--emu-common-colors-black);
      font-weight: var(--emu-common-font-weight-bold);
      letter-spacing: 0.65px;
      font-size: 10px;
      line-height: var(--emu-semantic-line-heights-narrow-medium);
      text-decoration: none;
      font-family: var(--emu-semantic-font-families-heading);
      display: inline-block;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      @include mq('medium-plus') {
        font-size: 13px;
        line-height: 18px;
        margin-right: 20px;
      }

      @include mq('large-plus') {
        margin-right: 25px;
      }
    }
  }

  &__content {
    &-inner {
      margin-left: 10px;
      width: 85%;

      @include mq('medium') {
        margin-left: 20px;
        width: calc(85% + min(3%, 20px));
      }

      @include mq('large-plus') {
        width: 85%;
      }
    }

    h2 {
      font-size: 14px;
      letter-spacing: 0.8px;
      line-height: var(--emu-common-line-heights-narrow-medium);
      font-weight: var(--emu-common-font-weight-bold);
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-xs);
      padding-top: var(--emu-common-spacing-xs);
      min-height: 40px; // to match live site

      @include mq('medium-plus') {
        font-size: 25px;
        letter-spacing: 1.25px;
        line-height: 34px;
        margin-bottom: 8px;
        -webkit-line-clamp: 2; // code from live site
        overflow: hidden;
        display: -webkit-box; // code from live site
        -webkit-box-orient: vertical; // code from live site
        min-height: 67.5px; // to match live site.
        padding-top: var(--emu-common-spacing-none);
      }

      @include mq('x-large') {
        display: block;
        margin-bottom: var(--emu-common-spacing-small);
      }

      a {
        color: var(--emu-semantic-colors-primary-dark-gray-100);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  &__date {
    display: none;
  }

  &__banner-img {
    display: block;
    max-width: 100%;
    width: 100%;

    // mq needed to make it look better
    @include mq('2000px') {
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  // article detail page design start
  &--article-detail-page {
    @include mq('large-plus') {
      width: 100%;
    }

    .article-banner-card {
      &__content-section {
        position: relative;
        padding-bottom: var(--emu-common-spacing-none);

        @include mq('large-plus') {
          width: 100%;
        }
      }

      &__content {
        @include mq('medium') {
          padding-bottom: 15px;
        }

        &-inner {
          margin-left: var(--emu-common-spacing-none);
          width: 100%;
        }

        h2 {
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          letter-spacing: 1.8px;
          line-height: 32px;
          padding-top: 5px;
          padding-bottom: 15px;
          padding-right: 10px;
          width: 100%;
          color: var(--emu-semantic-colors-primary-dark-gray-100);
          margin-bottom: var(--emu-common-spacing-small);
          pointer-events: none;
          -webkit-line-clamp: unset; // value from live site
          min-height: auto;

          @include mq('medium') {
            padding-top: 15px;
            padding-bottom: 20px;
          }

          @include mq('large-plus') {
            font-size: 32px;
            letter-spacing: 0;
            padding-top: 54px;
            padding-bottom: 5px;
          }
        }
      }

      &__date {
        display: block;
        font-size: 15px;
        letter-spacing: 0.75px;
        line-height: 20px;
        padding-right: 25px;
        padding-bottom: 10px;
        padding-top: 5px;
        color: var(--emu-semantic-colors-primary-dark-gray-700);
        font-family: var(--emu-semantic-font-families-heading);
        @include mq('medium') {
          padding-bottom: var(--emu-common-spacing-none);
        }
      }

      &__tag-container {
        padding-bottom: 5px;
        display: inline-block;

        @include mq('medium') {
          display: flex;
          padding-bottom: 10px;
        }
      }

      &__tag-list {
        a {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: 17px;
          padding-bottom: 5px;
          color: #7e7e7e; //used only once
          margin-right: 20px;
          font-weight: var(--emu-common-font-weight-regular);
          display: inline-block;

          &:hover {
            color: var(--emu-semantic-colors-primary-dark-gray-100);
          }

          @include mq('medium') {
            font-size: 11px;
            padding-bottom: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-medium);
          }

          @include mq('large-plus') {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            margin-right: 20px;
          }
        }
      }

      &__img-container {
        margin-bottom: 10px;
        @include mq('medium') {
          padding-bottom: 25px;
        }
      }

      &__banner-img {
        max-width: 750px; // to match live site
        width: 100%;
        margin: var(--emu-common-spacing-none) auto;
      }
    }

    &.article--bigger-img {
      .article-banner-card__banner-img {
        max-width: 1000px; //as per live
      }
    }
  }
}
